import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ICategoryArrayRes } from 'src/app/interfaces/category';
import { ICityArrayRes } from 'src/app/interfaces/city';
import { IPostArrayRes } from 'src/app/interfaces/post';
import { ISubCategoryArrayRes } from 'src/app/interfaces/sub-category';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GuestService {
  API_URL = environment.apiURL;
  route = 'guest';

  constructor(
    private http: HttpClient
  ) { }

  cities(): Observable<ICityArrayRes> {
    return this.http.post<ICityArrayRes>(`${this.API_URL}${this.route}/cities`, {});
  }

  categories(): Observable<ICategoryArrayRes> {
    return this.http.post<ICategoryArrayRes>(`${this.API_URL}${this.route}/categories`, {});
  }

  subCategories(): Observable<ISubCategoryArrayRes> {
    return this.http.post<ISubCategoryArrayRes>(`${this.API_URL}${this.route}/subcategories`, {});
  }

  posts(): Observable<IPostArrayRes> {
    return this.http.post<IPostArrayRes>(`${this.API_URL}${this.route}/posts`, {});
  }

  subCategoriesPosts(subcategoryId?: number): Observable<IPostArrayRes> {
    return this.http.post<IPostArrayRes>(`${this.API_URL}${this.route}/subcategories/${subcategoryId}`, {});
  }
}
