import { MapsAPILoader, MouseEvent } from '@agm/core';
import { Component, OnInit, AfterViewInit, NgZone, Input, ViewChild, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IMarker } from 'src/app/interfaces/maps';
import { AlertService } from 'src/app/services/alert/alert.service';
import { DataService } from 'src/app/services/data/data.service';

declare const google: any;

@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.sass']
})
export class MapsComponent implements OnInit, AfterViewInit {
  address = new FormControl('');
  searchBox: any;

  // google maps zoom level
  zoom = 15;

  // initial center position for the map
  lat = 25.54527412434738;
  lng = -103.4484176337719;

  marker: IMarker = {
    lat: 25.54527412434738,
    lng: -103.4484176337719,
    label: 'A',
    draggable: true,
    address: 'Calz Colón 418 Nte-1er P, Primero de Cobián Centro, 27000 Torreón, Coah.'
  };

  @Input() markerInput: IMarker;
  @Input() isUpdate: boolean;

  @ViewChild('filter', { static: true }) searchFilter: ElementRef;

  constructor(
    private alertService: AlertService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private dataService: DataService
  ) {}

  ngOnInit(): void {
    if (!this.isUpdate) {
      this.dataService.setLocationValue(this.marker);
    }

    if (this.markerInput){
      this.setLocation({
          coords : {
            lat: Number(this.markerInput.lat),
            lng: Number(this.markerInput.lng)
          }
      }, this.markerInput.address, false);
      this.address.setValue(this.markerInput.address);
      this.dataService.setLocationValue(this.marker);
    }
  }

  ngAfterViewInit(): void {
    this.mapLoader();
  }

  mapLoader(): void {
    const input = this.searchFilter.nativeElement;

    this.mapsAPILoader.load().then(() => {
      this.searchBox = new google.maps.places.Autocomplete(input, {
        types: ['address'],
        componentRestrictions: {
          country: 'mx'
        }
      });

      // if (this.isUpdate) {
      //   this.getCompanyAddress();
      // }

      this.searchBox.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place = this.searchBox.getPlace();
          if (!place.geometry) {
            return;
          }

          this.marker.lat = place.geometry.location.lat();
          this.marker.lng = place.geometry.location.lng();
          this.lat = this.marker.lat;
          this.lng = this.marker.lng;
          this.marker.draggable = true;
          this.marker.address = place.formatted_address;

          this.address.setValue(this.marker.address);
          this.dataService.setLocationValue(this.marker);
        });
      });
    });
  }

  clickedMarker(label: string) {
    console.log(`clicked the marker: ${label}`);
  }

  mapClicked(event: MouseEvent) {
    this.setLocation(event);
  }

  markerDragEnd(marker: IMarker, event: MouseEvent): void {
    this.setLocation(event);
  }

  setLocation(event: MouseEvent, address: string = '', loadSavedAddress = true): void {
    this.marker.lat = event.coords.lat;
    this.marker.lng = event.coords.lng;
    this.marker.address = address;
    this.lat = event.coords.lat;
    this.lng = event.coords.lng;
    this.marker.draggable = true;

    if (loadSavedAddress) {
      this.getAddress();
    }
  }

  getAddress(): void {
    if (navigator.geolocation) {
      const geocoder = new google.maps.Geocoder();
      const latlng = new google.maps.LatLng(this.marker.lat, this.marker.lng);
      const request: any = {
        latLng: latlng,
      };
      geocoder.geocode(request, (results, status) => {
        this.marker.address = results[0].formatted_address;
        this.address.setValue(this.marker.address);
        this.dataService.setLocationValue(this.marker);
      });
      this.lat = this.marker.lat;
      this.lng = this.marker.lng;
    }
  }

  getCompanyAddress() {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({
      address: this.address,
    }, (results, status) => {
      if (status === 'OK') {
        const place = results[0];
        if (!place.geometry){
          return;
        }

        this.marker.lat = place.geometry.location.lat();
        this.marker.lng = place.geometry.location.lng();
        this.lat = this.marker.lat;
        this.lng = this.marker.lng;
        this.marker.draggable = true;
        this.marker.address = place.formatted_address;
      } else {
        this.alertService.warningMessage('Geocode was not successful for the following reason: ' + status, 'Aviso');
      }
    });
  }
}
