import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { fromEvent, Subscription } from 'rxjs';
import { Address } from 'src/app/core/models/address';
import { InDateFilter } from 'src/app/interfaces/data-filter';
import { AlertService } from 'src/app/services/alert/alert.service';
import { FilterDataService } from 'src/app/services/data/filter-data.service';
import { AddressesService } from 'src/app/services/supplier/addresses.service';
import { FormDialogComponent } from '../../dialogs/form-dialog/form-dialog.component';

@Component({
  selector: 'app-addresses-list',
  templateUrl: './addresses-list.component.html',
  styleUrls: ['./addresses-list.component.sass']
})
export class AddressesListComponent implements OnInit {
  dataSource = new MatTableDataSource<Address>();
  disableAddressTooltip = true;

  skeletonActive = true;

  searchControl = new FormControl('');
  @ViewChild('filter', { static: true }) filter: ElementRef;

  @Input() selectAddress: boolean;
  @Output() selectedAddressChange = new EventEmitter<Address>();

  constructor(
    public dialog: MatDialog,
    private addressService: AddressesService,
    private notificationService: AlertService,
  ) {}

  ngOnInit(): void {
    this.refreshData();
    if (this.selectAddress) {
      this.disableAddressTooltip = false;
    }
  }

  refreshData(): void {
    this.skeletonActive = true;
    setTimeout(() => {
      this.getItems();
    }, 500);
  }

  clear(): void {
    this.searchControl.setValue('');
    this.dataSource.filter = this.filter.nativeElement.value;
  }

  selectAddressAction(item: Address): void {
    this.selectedAddressChange.emit(item);
  }

  confirmMainAddresUpdate(item: Address): void {
    this.notificationService.confirmDialog(
      '',
      'Cancelar',
      'Confirmar',
      '¿Marcar como dirección principal?'
    )
    .then((result) => {
      if (result.isConfirmed) {
        this.updateMainAddress(item.id);
      }
    });
  }

  openFormDialog(item?: Address): void {
    const dialogRef = this.dialog.open(FormDialogComponent, {
      data: {
        action: item ? 'edit' : 'add',
        data: item
      },
      maxWidth: '65vw',
      height: '770px',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.status) {
          this.refreshData();
          this.getItems();
        }
      }
    });
  }

  confirmDelete(item: Address): void {
    this.notificationService.confirmDialog(
      '',
      'Cancelar',
      'Confirmar',
      '¿Desea eliminar ésta dirección?'
    )
    .then((result) => {
      if (result.isConfirmed) {
        this.deleteItem(item.id);
      }
    });
  }

  getItems(): void {
    this.addressService.index().subscribe(
      res => {
        if (res.status) {
          this.dataSource.data = res.data;
          fromEvent(this.filter.nativeElement, 'keyup').subscribe(() => {
            this.dataSource.filter = this.filter.nativeElement.value;
          });
        }
        this.skeletonActive = false;
      },
      error => {
        this.notificationService.warningMessage(error, 'Aviso');
        this.skeletonActive = false;
      }
    );
  }

  deleteItem(id: number): void {
    this.addressService.delete(id).subscribe(
      res => {
        if (res.status) {
          this.getItems();
          this.notificationService.successDialog('Acción completa');
        } else {
          this.notificationService.warningMessage(res.msg);
        }
      },
      error => {
        this.notificationService.warningMessage(error, 'Aviso');
      }
    );
  }

  updateMainAddress(id: number): void {
    this.addressService.updateMainAddress(id).subscribe(
      res => {
        if (res.status) {
          this.getItems();
          this.notificationService.successDialog('Acción completa');
        } else {
          this.notificationService.warningMessage(res.msg);
        }
      },
      error => {
        this.notificationService.warningMessage(error, 'Aviso');
      }
    );
  }
}
