import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MaterialModule } from './material.module';
import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';
import { MatCarouselModule } from 'ng-mat-carousel';
import { ClickOutsideModule } from 'ng-click-outside';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgApexchartsModule } from 'ng-apexcharts';

import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';

import localeEsMx from '@angular/common/locales/es-MX';
import { registerLocaleData } from '@angular/common';

import { environment } from 'src/environments/environment';
import { PrimengModule } from './primeng.module';

import { CookieService } from 'ngx-cookie-service';
import { AgmCoreModule } from '@agm/core';
import { NgxMaskModule, IConfig } from 'ngx-mask';

import { FormDialogComponent as PostFormDialog } from '../sections/supplier/posts/dialogs/form-dialog/form-dialog.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false
};

registerLocaleData(localeEsMx, 'es');

const sharedModules = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    PrimengModule,
    NgbModule,
    NgxSpinnerModule,
    PerfectScrollbarModule,
    MatCarouselModule,
    ClickOutsideModule,
    NgxMatTimepickerModule.setLocale('es'),
    SweetAlert2Module.forRoot(),
    NgApexchartsModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AgmCoreModule.forRoot({
      apiKey: environment.googleMaps.apiKey,
      libraries: ["places"]
    }),
    NgxMaskModule.forRoot()
];

@NgModule({
  declarations: [],
  imports: [sharedModules],
  exports: [sharedModules],
  providers : [
    { provide: LOCALE_ID, useValue: 'es' },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    CookieService,
    DatePipe,
    PostFormDialog
  ]
})
export class SharedModule {}
