import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  HostListener,
  AfterViewInit
} from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfigService } from 'src/app/config/config.service';
import { RightSidebarService } from 'src/app/core/service/rightsidebar.service';
import { WINDOW } from 'src/app/core/service/window.service';
import { IGlobal } from 'src/app/interfaces/global';
import { INotification } from 'src/app/interfaces/notification';
import { IUserData } from 'src/app/interfaces/user-auth';
import { AlertService } from 'src/app/services/alert/alert.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DataService } from 'src/app/services/data/data.service';
import { NotificationValidationService } from 'src/app/services/messaging/notification-validation.service';
import { NotificationService } from 'src/app/services/messaging/notification.service';
import { NotificationsService } from 'src/app/services/supplier/notifications.service';
const document: any = window.document;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  public config: any = {};
  isNavbarCollapsed = true;
  isNavbarShow: boolean;
  flagvalue;
  countryName;
  langStoreValue: string;
  defaultFlag: string;
  user: IUserData;

  notifications: any[];
  categoryURL: string;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private dataService: RightSidebarService,
    private configService: ConfigService,
    private authService: AuthService,
    private router: Router,
    private storageDataService: DataService,
    private alertService: AlertService,
    private globalDataService: DataService,
    private notificationService: NotificationService,
    private spinner: NgxSpinnerService,
    private notificationDbService: NotificationsService,
    private notificationValidation: NotificationValidationService
    ) {
    this.user = this.storageDataService.profileValue;
    this.config = this.configService.configData;
    this.categoryURL = this.globalDataService.categoryURL;

    // Listen to notification changes
    this.globalDataService.notifications.subscribe((data) => {
      this.notifications = data;
    });
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const offset =
      this.window.pageYOffset ||
      this.document.documentElement.scrollTop ||
      this.document.body.scrollTop ||
      0;
    if (offset > 50) {
      this.isNavbarShow = true;
    } else {
      this.isNavbarShow = false;
    }
  }

  ngOnInit(): void {
  }

  async ngAfterViewInit(): Promise<void> {
    // set theme on startup
    if (localStorage.getItem('theme')) {
      this.renderer.removeClass(this.document.body, this.config.layout.variant);
      this.renderer.addClass(this.document.body, localStorage.getItem('theme'));
    } else {
      this.renderer.addClass(this.document.body, this.config.layout.variant);
    }

    if (localStorage.getItem('menuOption')) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem('menuOption')
      );
    } else {
      this.renderer.addClass(
        this.document.body,
        'menu_' + this.config.layout.sidebar.backgroundColor
      );
    }

    if (localStorage.getItem('choose_logoheader')) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem('choose_logoheader')
      );
    } else {
      this.renderer.addClass(
        this.document.body,
        'logo-' + this.config.layout.logo_bg_color
      );
    }

    if (localStorage.getItem('sidebar_status')) {
      if (localStorage.getItem('sidebar_status') === 'close') {
        this.renderer.addClass(this.document.body, 'side-closed');
        this.renderer.addClass(this.document.body, 'submenu-closed');
      } else {
        this.renderer.removeClass(this.document.body, 'side-closed');
        this.renderer.removeClass(this.document.body, 'submenu-closed');
      }
    } else {
      if (this.config.layout.sidebar.collapsed === true) {
        this.renderer.addClass(this.document.body, 'side-closed');
        this.renderer.addClass(this.document.body, 'submenu-closed');
      }
    }

    this.verify(); // Check if user token is valid
    this.globalDataService.getProfile();
    this.getStoredNotifications();
  }

  callFullscreen(): void {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }

  mobileMenuSidebarOpen(event: any, className: string): void {
    const hasClass = event.target.classList.contains(className);
    if (hasClass) {
      this.renderer.removeClass(this.document.body, className);
    } else {
      this.renderer.addClass(this.document.body, className);
    }
  }

  callSidemenuCollapse(): void {
    const hasClass = this.document.body.classList.contains('side-closed');
    if (hasClass) {
      this.renderer.removeClass(this.document.body, 'side-closed');
      this.renderer.removeClass(this.document.body, 'submenu-closed');
    } else {
      this.renderer.addClass(this.document.body, 'side-closed');
      this.renderer.addClass(this.document.body, 'submenu-closed');
    }
  }

  public toggleRightSidebar(): void {
    this.dataService.changeMsg(
      (this.dataService.currentStatus._isScalar = !this.dataService
        .currentStatus._isScalar)
    );
  }

  onErrorPicture(event: any): void {
    event.target.src = "assets/images/iparty/ipa_user.png";
    event.onerror = null;
  }

  notificationClicked(notification: INotification, event: any): void {
    this.notificationValidation.validate(notification);
  }

  deleteNotification(index: number): void {
    this.globalDataService.deleteNotification(index);
  }

  deleteAllNotis(): void {
    this.globalDataService.deleteAllNotifications();
  }

  navigateTo(route: string): void {
    this.router.navigate([route]);
  }

  clearData(): void {
    // Delete external ID and set OneSignal subscription to false
    // this.notificationService.updateSubscription(false);
    this.notificationService.deleteExternalID();

    this.router.navigate(['/'], { replaceUrl: true }).then(() => {
      // Remove service worker
      // this.notificationService.unregister();

      // remove user from local storage to log user out
      this.globalDataService.clearAll();

      // this.notificationService.deleteIndexedDB();
      // window.location.reload();
    });
  }

  getStoredNotifications(): void {
    // Validate if is not admin
    if (this.user.role_id !== 5) {
      // Get stored notifications - last 3 days history
      this.notificationDbService.received(this.user)
      .subscribe(response => {
        if (response.status) {
          this.globalDataService.setNotificationValue(response.data);
        }
      });
    }
  }

  signOut(): any {
    this.alertService.confirmDialog(
      '',
      'Cancelar',
      'Si',
      '¿Estás seguro de cerrar sesión?'
    )
    .then(async (response) => {
      if (response.isConfirmed) {
        this.logout();
      }
    });
  }

  logout(): any {
    this.spinner.show();

    // Get OneSignal Player ID
    // const playerID = await this.notificationService.getUserID();

    // Logout from site
    this.authService.logout().subscribe(async (result: IGlobal) => {
      if (result.status) {
        this.clearData();
      }
      this.spinner.hide();
    });
  }

  verify(): void {
    this.authService.verify().subscribe(
      (result: IGlobal) => {
      if (!result.status) {
        this.authService.refresh().subscribe((response: IGlobal) => {
          if (response.status) {
            this.globalDataService.setTokenValue(response.data);
            this.globalDataService.setGlobalData();
            window.location.reload();
          } else {
            this.clearData();
          }
        });
      }
    },
    (error: any) => {
       this.clearData();
    });
  }
}
