import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthLayoutComponent } from './app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './app-layout/main-layout/main-layout.component';
import { SharedModule } from '../shared/shared.module';
import { SkeletonListComponent } from './skeleton/skeleton-list/skeleton-list.component';
import { HeaderComponent } from './header/header.component';
import { PageLoaderComponent } from './page-loader/page-loader.component';
import { RightSidebarComponent } from './right-sidebar/right-sidebar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NoContentComponent } from './no-content/no-content/no-content.component';
import { FooterComponent } from './footer/footer.component';

const components = [
  HeaderComponent,
  PageLoaderComponent,
  SidebarComponent,
  RightSidebarComponent,
  AuthLayoutComponent,
  MainLayoutComponent,
  SkeletonListComponent,
  NoContentComponent,
  FooterComponent
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [components],
  exports: [components]
})
export class LayoutModule {}
