<!-- <nav #navbar class="navbar" [ngClass]="isNavbarShow ? 'active' : 'inactive'"> -->
<nav #navbar class="navbar" [ngClass]="'active'">
  <div class="container-fluid">
    <div class="navbar-header">
      <a href="#" onClick="return false;" class="navbar-toggle collapsed"
        (click)="isNavbarCollapsed = !isNavbarCollapsed" aria-expanded="false"></a>
      <!-- mobile menu bars -->
      <a href="#" onClick="return false;" class="bars" (click)="mobileMenuSidebarOpen($event,'overlay-open')"></a>
      <a class="navbar-brand" routerLink="dashboard/main">
        <img class="logo-img"/>
      </a>
    </div>
    <div class="collapse navbar-collapse" [ngbCollapse]="isNavbarCollapsed">
      <ul class="pull-left collapse-menu-icon">
        <li>
          <button mat-icon-button (click)="callSidemenuCollapse()" class="sidemenu-collapse">
            <mat-icon>menu</mat-icon>
          </button>
        </li>
      </ul>
      <ul class="nav navbar-nav navbar-right">
        <!-- Full Screen Button -->
        <li class="fullscreen">
          <button mat-icon-button (click)="callFullscreen()" class=" nav-notification-icons">
            <mat-icon>fullscreen</mat-icon>
          </button>
        </li>
        <!-- #END# Full Screen Button -->
        <!-- #START# Notifications-->
        <li class="nav-item" ngbDropdown [autoClose]="true">
          <button mat-icon-button ngbDropdownToggle class="nav-notification-icons">
            <mat-icon [matBadge]="notifications.length" matBadgeColor="warn">notifications_none</mat-icon>
          </button>
          <div ngbDropdownMenu class="notification-dropdown pullDown">
            <div class="noti-list" style="position: relative; width: 420px; max-height: 325px;" [perfectScrollbar]>
              <ul class="menu">
                <li *ngIf="notifications.length > 0; else empty">
                  <a *ngFor="let notification of notifications; index as notiIdx;" 
                  (click)="notificationClicked(notification, this)" ngbDropdownToggle>
                    <span class="table-img msg-user">
                      <img class="mr-0 category-img" width="50" height="50" [src]="notification.icon">
                      <!-- <mat-icon aria-label="evento" class="col-iparty-purple mr-1">celebration</mat-icon> -->
                    </span>
                    <span class="menu-info">
                      <span class="menu-title">{{notification.title}}</span>
                      <span class="menu-desc">
                        <i class="material-icons">access_time</i> {{notification.created_at | date: 'd MMM, h:mm a'}}
                      </span>
                      <span class="menu-desc">{{notification.message}}</span>
                    </span>
                    <!-- <span class="menu-actions">    
                      <button mat-icon-button color="warn" 
                      #tooltip="matTooltip"
                      matTooltip="Eliminar"
                      matTooltipPosition="right"
                      aria-label="Eliminar"
                      (click)="deleteNotification(notiIdx)">
                        <mat-icon>clear</mat-icon>
                      </button>
                    </span> -->
                  </a>
                </li>
                <ng-template #empty>
                  <a href="#" onClick="return false;">
                    <span class="table-img msg-user">
                      <mat-icon aria-label="evento" class="col-gray mr-1">celebration</mat-icon>
                    </span>
                    <span class="menu-info">
                      <span class="menu-title mt-3">Sin notificaciones</span>
                      <span class="menu-desc">
                      </span>
                      <span class="menu-desc"></span>
                    </span>
                  </a>
                </ng-template>
              </ul>
            </div>
            <!-- <a *ngIf="notifications.length > 1" class="text-center d-block border-top py-1">
              <button mat-flat-button
              (click)="deleteAllNotis()">
                <mat-icon>delete</mat-icon>
                <span>Eliminar todas</span>
              </button>
            </a> -->
          </div>
        </li>
        <!-- #END# Notifications-->
        <li class="nav-item user_profile" ngbDropdown [autoClose]="true">
          <a href="#" onClick="return false;" ngbDropdownToggle role="button" class="nav-notification-icons pt-0">
            <img src="{{user.picture ? user.picture : 'assets/images/iparty/ipa_user.png'}}" (error)="onErrorPicture($event)" class="rounded-circle" width="32" height="32" alt="Usuario">
          </a>
          <div ngbDropdownMenu class="notification-dropdown user-opt pullDown">
            <div class="noti-list" style="position: relative; max-width: 600px; max-height: 300px;" [perfectScrollbar]>
              <ul class="menu">
                <li class="body">
                  <ul class="user_dw_menu">
                    <li *ngIf="user.role_id === 5 || (user.role_id === 3 && user.supplier && user.supplier.active)">
                      <a (click)="navigateTo('/configuration/profile')" ngbDropdownToggle>
                        <mat-icon aria-hidden="false" class="mr-2">person</mat-icon>Perfil
                      </a>
                    </li>
                    <!-- Admin options -->
                    <li *ngIf="user.role_id === 5">
                      <a (click)="navigateTo('/configuration/profile/config')" ngbDropdownToggle>
                        <mat-icon aria-hidden="false" class="mr-2">settings</mat-icon>Configuración
                      </a>
                    </li>
                     <!-- Supplier options -->
                     <li *ngIf="user.role_id === 3 && user.supplier && user.supplier.active">
                      <a (click)="navigateTo('/configuration/subscription')" ngbDropdownToggle>
                        <mat-icon aria-hidden="false" class="mr-2">card_membership</mat-icon>Suscripción
                      </a>
                    </li>
                    <!-- <li>
                      <a onClick="return false;" ngbDropdownToggle>
                        <mat-icon aria-hidden="false" class="mr-2">help</mat-icon>Ayuda
                      </a>
                    </li> -->
                    <li>
                      <a (click)="$event.stopPropagation()" (click)="signOut()" ngbDropdownToggle>
                        <mat-icon aria-hidden="false" class="mr-2">power_settings_new</mat-icon>Cerrar sesión
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <!-- #END# Options -->
      </ul>
    </div>
  </div>
</nav>
