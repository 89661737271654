<div class="footer">
    <div class="w-100 d-flex flex-column f-content justify-content-center">
        <div class="d-flex justify-content-between">
            <a class="black-link">©{{copyRightYear}} IPARTY MEXICO SAS DE CV</a>
            <a class="black-link" href="https://ipartymexico.com/" target="_blank">Ir a ipartymexico.com</a>
        </div>
        <div class="d-flex justify-content-between">
            <div class="d-flex flex-column">
                <a class="col-blue link" href="assets/docs/t&c/iparty_TC.pdf" target="_blank">Términos y condiciones</a>
            </div>
            <div class="d-flex flex-column">
                <a class="col-blue link" href="assets/docs/t&c/iparty_AP.pdf" target="_blank">Aviso de privacidad</a>
            </div>
        </div>
    </div>
</div>