import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { IUserAuthRes, IUserSocial } from '../../interfaces/user-auth';
import { environment } from 'src/environments/environment';
import { DataService } from 'src/app/services/data/data.service';
import { IGlobal } from 'src/app/interfaces/global';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  API_URL = environment.apiURL;
  route = 'auth';

  constructor(
    private http: HttpClient,
    private dataService: DataService,
  ) { }

  login(email: string, password: string): Observable<IUserAuthRes> {
    return this.http.post<IUserAuthRes>(`${this.API_URL}${this.route}/account`, {
        email,
        password
      }).pipe(
        map((response) => {
          if (response.status) {
            const roleId = response.data.user.role_id;
            const supplier = response.data.user.supplier;
            if (roleId === 5 || roleId === 4 || (roleId === 3 && supplier)) {
              // store user details and jwt token in local storage to keep user logged in between page refreshes
              this.dataService.setTokenValue(response.data.access_token);
            }
          }
          return response;
        })
      );
  }

  socialLogin(user: IUserSocial): Observable<IUserAuthRes> {
    return this.http.post<IUserAuthRes>(`${this.API_URL}${this.route}/social`, user).pipe(
      map((response) => {
        if (response.status) {
          const roleId = response.data.user.role_id;
          const supplier = response.data.user.supplier;
          if (roleId === 5 || roleId === 4 || (roleId === 3 && supplier)) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            this.dataService.setTokenValue(response.data.access_token);
          }
        }
        return response;
      })
    );
  }

  logout(playerID?: string): Observable<IGlobal> {
    return this.http.post<IGlobal>(`${this.API_URL}logout`, {
      refresh_token: this.dataService.tokenValue.refreshToken,
      // player_id: playerID
    });
  }

  refresh(): Observable<IGlobal> {
    return this.http.post<IGlobal>(`${this.API_URL}${this.route}/refresh`, {
      refresh_token: this.dataService.tokenValue.refreshToken
    });
  }

  verify(): Observable<IGlobal> {
    return this.http.post<IGlobal>(`${this.API_URL}${this.route}/verify`, {});
  }
}
