<div class="row">
    <div class="d-flex justify-content-center" style="width: 100%;">
        <div class="d-flex flex-column justify-content-center" 
        style="width: 50%; max-width: 50%; height: fit-content; padding: 0px 20px;">
            <h5 class="">Imagen original</h5>
            <image-cropper
            [imageChangedEvent]="imageChangedEvent"
            [aspectRatio]="1/1"
            [onlyScaleDown]="true"
            [roundCropper]="false"
            [cropperMinWidth]="cropperMinWidth"
            [cropperMinHeight]="cropperMinHeight"
            [cropperMaxWidth]="1600"
            [cropperMaxHeight]="1600"
            [maintainAspectRatio]="true"
            [containWithinAspectRatio]="true"
            [imageQuality]="70"
            [alignImage]="'center'"
            (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded()"
            (cropperReady)="cropperReady($event)"
            (loadImageFailed)="loadImageFailed()"
            >
            </image-cropper>
            <div *ngIf="loading" class="d-flex justify-content-center align-items-center config-loading">
                Cargando...
              </div>
            <p style="font-weight: 700;">*La imagen no se puede reajustar si la resolución es menor a {{cropperMinWidth}} x {{cropperMinHeight}} pixeles</p>
        </div>

        <div class="d-flex flex-column justify-content-center" 
        style="width: 50%; max-width: 50%; height: fit-content; padding: 0px 20px;">
            <h5 class="">Imagen editada</h5>
            <img class="" [src]="croppedImage" [style.border]="croppedImage ? '1px solid black' : 'none'" />
            <div *ngIf="loading" class="d-flex justify-content-center align-items-center config-loading" style="margin-top: 20px;">
                Cargando...
            </div>
        </div>
    </div>
</div>