<div class="custom-skeleton p-4">
    <ul class="m-0 p-0">
        <li *ngFor="let item of items" class="skeleton-item">
            <div class="d-flex">
                <p-skeleton shape="square" size="4rem" styleClass="mr-2"></p-skeleton>
                <div class="mt-1" style="flex: 1">
                    <p-skeleton width="100%" height="2rem" styleClass="mb-2"></p-skeleton>
                    <p-skeleton width="75%"></p-skeleton>
                </div>
            </div>
        </li>
    </ul>
</div>
