import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Categories } from 'src/app/core/models/categories';
import { Post } from 'src/app/core/models/post';
import { SubCategories } from 'src/app/core/models/sub-categories';
import { IMarker } from 'src/app/interfaces/maps';
import { INotification } from 'src/app/interfaces/notification';
import { IPostApproved, IPostFilterValue } from 'src/app/interfaces/post';
import { IAccessToken, IEventDataType, IRole, IUserData } from 'src/app/interfaces/user-auth';
import { environment } from 'src/environments/environment';
import { AlertService } from '../alert/alert.service';
import { ProfileService } from '../global/profile.service';
import { GuestService } from '../guest/guest.service';
import { ResourcesService } from '../resources/resources.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  // URLs for resources
  public postURL = `${environment.resourceURL}/posts/`;
  public categoryURL = `${environment.resourceURL}/categories/`;

  // Post approved statuses
  approvedStatuses: IPostApproved[] = [
    {description: 'En revisión', color: 'accent', approved: 2},
    {description: 'Aprobada', color: 'primary', approved: 1},
    {description: 'Rechazada', color: 'warn', approved: 0}
  ];

  // Post filter active
  postFilterStatuses: IPostFilterValue[] = [
    {description: 'Activo', color: 'primary', value: true, tooltip: ''},
    {description: 'Inactivo', color: 'warn', value: false, tooltip: ''}
  ];

  // List of available roles
  public roles: IRole[] = [
    { id: 1, name: 'Invitado'},
    { id: 2, name: 'Cliente'},
    { id: 3, name: 'Proveedor'},
    { id: 4, name: 'Moderador'},
    { id: 5, name: 'Administrador'},
  ];

  // Event data type
  public eventDataType: IEventDataType[] = [
    { id: 1, name: 'Todos', key: 'all'},
    { id: 2, name: 'Vigentes', key: 'events'},
    { id: 3, name: 'Finalizados', key: 'history'},
  ];

  // Global Data INFO
  private categoriesSubject: BehaviorSubject<Categories[]>;
  public categories: Observable<Categories[]>;

  private selectedCategorySubject: BehaviorSubject<Categories>;
  public selectedCategory: Observable<Categories>;

  private subCategoriesSubject: BehaviorSubject<SubCategories[]>;
  public subCategories: Observable<SubCategories[]>;

  private iconsSubject: BehaviorSubject<string[]>;
  public icons: Observable<string[]>;

  private profileSubject: BehaviorSubject<IUserData>;
  public profile: Observable<IUserData>;

  private tokenSubject: BehaviorSubject<IAccessToken>;
  public token: Observable<IAccessToken>;


  // MAPS LOCATION
  private locationSubject: BehaviorSubject<IMarker>;
  public location: Observable<IMarker>;


  // Notification Data
  private notificationsSubject: BehaviorSubject<INotification[]>;
  public notifications: Observable<INotification[]>;

  private notiTokenSubject: BehaviorSubject<string>;
  public notiToken: Observable<string>;

  constructor(
    private guestService: GuestService,
    private profileService: ProfileService,
    private resourcesService: ResourcesService,
    private notificationService: AlertService,
    private datepipe: DatePipe
  ) {
    this.setData();
  }

  private setData(): void {
    // PROFILE
    this.profileSubject = new BehaviorSubject<IUserData>(
      JSON.parse(localStorage.getItem('profile'))
    );
    this.profile = this.profileSubject.asObservable();


    // TOKEN
    this.tokenSubject = new BehaviorSubject<IAccessToken>(
      JSON.parse(localStorage.getItem('token'))
    );
    this.token = this.tokenSubject.asObservable();


    // CATEGORIES
    this.categoriesSubject = new BehaviorSubject<Categories[]>(
      JSON.parse(localStorage.getItem('categories'))
    );
    this.categories = this.categoriesSubject.asObservable();


    // SUBCATEGORIES
    this.subCategoriesSubject = new BehaviorSubject<SubCategories[]>(
      JSON.parse(localStorage.getItem('subcategories'))
    );
    this.subCategories = this.subCategoriesSubject.asObservable();


    // ICONS
    this.iconsSubject = new BehaviorSubject<string[]>(
      JSON.parse(localStorage.getItem('icons'))
    );
    this.icons = this.iconsSubject.asObservable();


    // SELECTED CATEGORY
    this.selectedCategorySubject = new BehaviorSubject<Categories>(null);
    this.selectedCategory = this.selectedCategorySubject.asObservable();


    // NOTIFICATION MESSAGES
    const notiJsonVal =  JSON.parse(localStorage.getItem('notifications'));
    this.notificationsSubject = new BehaviorSubject<INotification[]>(
      notiJsonVal ? notiJsonVal : []
    );
    this.notifications = this.notificationsSubject.asObservable();

    this.notiTokenSubject = new BehaviorSubject<string>(
      JSON.parse(localStorage.getItem('noti-token'))
    );
    this.notiToken = this.notiTokenSubject.asObservable();


    // LOCATION
    this.locationSubject = new BehaviorSubject<IMarker>({} as IMarker);
    this.location = this.locationSubject.asObservable();
  }


   // TOKEN ACCESS
   public get tokenValue(): IAccessToken {
    return this.tokenSubject.value;
  }
  public setTokenValue(token: IAccessToken): void {
    localStorage.setItem('token', JSON.stringify(token));
    this.tokenSubject.next(token);
  }


  // CATEGORIES
  public get categoriesValue(): Categories[] {
    if (!this.categoriesSubject.value) {
      this.getCategories();
    }
    return this.categoriesSubject.value;
  }
  public setCategoriesValue(categories: Categories[]): void {
    localStorage.setItem('categories', JSON.stringify(categories));
    this.categoriesSubject.next(categories);
  }

  // SUBCATEGORIES
  public get subCategoriesValue(): SubCategories[] {
    if (!this.subCategoriesSubject.value) {
      this.getSubCategories();
    }
    return this.subCategoriesSubject.value;
  }
  public setSubCategoriesValue(subCategories: SubCategories[]): void {
    localStorage.setItem('subcategories', JSON.stringify(subCategories));
    this.subCategoriesSubject.next(subCategories);
  }

  // SELECTED CATEGORY
  public get selectedCategoryValue(): Categories {
    return this.selectedCategorySubject.value;
  }
  public setSelectedCategory(categories: Categories): void {
    this.selectedCategorySubject.next(categories);
  }

  // ICONS
  public get iconsValue(): string[] {
    if (!this.iconsSubject.value) {
      this.getIconResources();
    }
    return this.iconsSubject.value;
  }
  public setIconsValue(icons: string[]): void {
    localStorage.setItem('icons', JSON.stringify(icons));
    this.iconsSubject.next(icons);
  }

  // PROFILE
  public get profileValue(): IUserData {
    return this.profileSubject.value;
  }
  public setProfileValue(profile: IUserData): void {
    localStorage.setItem('profile', JSON.stringify(profile));
    this.profileSubject.next(profile);
  }

  // LOCATION
  public get locationValue(): IMarker {
    return this.locationSubject.value;
  }
  public setLocationValue(value: IMarker): void {
    this.locationSubject.next(value);
  }

  // NOTIFICATIONS
  public get notificationValue(): INotification[] {
    return this.notificationsSubject.value;
  }
  public setNotificationValue(notifications: any[]): void {
    this.notificationsSubject.next(notifications);
    localStorage.setItem('notifications', JSON.stringify(this.notificationsSubject.value));
  }
  public deleteNotification(index: number): void {
    this.notificationsSubject.value.splice(index, 1);
    localStorage.setItem('notifications', JSON.stringify(this.notificationsSubject.value));
    this.notificationsSubject.next(this.notificationsSubject.value);
  }
  public deleteAllNotifications(): void {
    this.notificationsSubject.next([]);
    localStorage.setItem('notifications', JSON.stringify([]));
  }

  // NOTIFICATION TOKEN
  public get notiTokenValue(): string {
    return this.notiTokenSubject.value;
  }
  public setNotiTokenValue(token: string): void {
    localStorage.setItem('noti-token', JSON.stringify(token));
    this.notiTokenSubject.next(token);
  }


  // CLEAR DATA
  public clearAll(): void {
    localStorage.clear();
    // sessionStorage.clear();
    // this.cookieService.deleteAll();
    this.setData();
  }


  // Method to set global data
  public setGlobalData(): void {
    if (!this.categoriesSubject.value) { // CATEGORIES
      this.getCategories(); // get and set categories from guest service
    }

    if (!this.subCategoriesSubject.value) { // SUBCATEGORIES
      this.getSubCategories(); // get and set subcategories from guest service
    }

    if (!this.iconsSubject.value) { // ICON RESOURCES
      this.getIconResources(); // get and set icons from guest service
    }
  }


  // GENERATE RESOURCE URL - SHOW IMAGES
  public postFirstMiniImageURL(post: Post): string {
    // :post_id/:image/?:preview
    return `${this.postURL}${post.id}/${post.resources.length > 0 ? post.resources[0].name : 'default.jpg'}/preview}`;
  }

  public postMiniImageURL(id: number, name: string): string {
    // :post_id/:image/:preview
    return `${this.postURL}${id}/${name}/preview}`;
  }

  public postImageURL(id: number, name: string): string {
    // :post_id/:image/
    return `${this.postURL}${id}/${name}`;
  }


  // DATES
  getDate(): string {
    const today = new Date();
    return `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
  }

  // Methods with http services
  async getProfile(): Promise<any> {
    const result = await this.profileService.get()
      .toPromise()
      .then((res) => {
        if (res.status) {
          this.setProfileValue(res.data);
        }
        return res.status;
      })
      .catch((err) => {
        return false;
      });
    return result;
  }

  getCategories(): void {
    this.guestService.categories().subscribe(
      res => {
        this.setCategoriesValue(res.data);
      },
      error => {
        this.notificationService.warningMessage(error, 'Categorías');
      }
    );
  }

  getSubCategories(): void {
    this.guestService.subCategories().subscribe(
      res => {
        this.setSubCategoriesValue(res.data);
      },
      error => {
        this.notificationService.warningMessage(error, 'Sub-Categorías');
      }
    );
  }

  getIconResources(): void {
    this.resourcesService.icons().subscribe(
      res => {
        if (res.status) {
          this.setIconsValue(res.data);
        }
      },
      error => {
        this.notificationService.warningMessage(error, 'Recurso - Iconos');
      }
    );
  }

  getDateNoTzFormattedString(date: string): string {
    return this.datepipe.transform(this.getDateNoTzString(date), 'd MMMM, y');
  }

  getDateNoTzString(date: string): string {
    return date.split('T')[0];
  }
}
