import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { timer } from 'rxjs';
import { ICharge, IPaymentData, IPaymentRes, IUserPayment } from 'src/app/interfaces/payment';
import { AlertService } from 'src/app/services/alert/alert.service';
import { DataService } from 'src/app/services/data/data.service';
import { OpenpayService } from 'src/app/services/payment/openpay.service';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { FormValidatorService } from 'src/app/services/validator/form-validator.service';

@Component({
  selector: 'app-payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.sass']
})
export class PaymentFormComponent implements OnInit {
  form: FormGroup;
  dialogTitle: string;
  paymentData: IPaymentData;
  userPaymentData: IUserPayment;
  result: ICharge;
  amount = 299;

  cardErrorMsg: string;
  paymentFormMsgs = [];

  paymentBtn: MatProgressButtonOptions = {
    active: false,
    text: 'Pagar',
    spinnerSize: 18,
    raised: true,
    stroked: false,
    buttonColor: 'primary',
    spinnerColor: 'accent',
    fullWidth: true,
    disabled: false,
    customClass: '',
    mode: 'indeterminate'
  };

  // Card Masks
  cardMasks = {
    typeOne: 'XXXX XXXX XXXX XXXX',
    typeTwo: 'XXXX XXXXXX XXXXX'
  };

  cvvMasks = {
    typeOne: 'XXX',
    typeTwo: 'XXXX'
  };
  cardMask = this.cardMasks.typeOne;
  cvvMask = this.cvvMasks.typeOne;

  // Card type validation
  amexRegEx = new RegExp('^3[47]');

  constructor(
    public dialogRef: MatDialogRef<PaymentFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private dataService: DataService,
    private notificationService: AlertService,
    private formValidator: FormValidatorService,
    private openpayService: OpenpayService,
    private paymentService: PaymentService
  ) {
    this.dialogTitle = 'Generar pago';
    this.form = this.createForm();
  }

  ngOnInit(): void {
    // Openpay
    this.openpayService.setInitialConfig();
    this.openpayService.getDeviceSessionId();
  }

  onClose(): void {
    this.dialogRef.close(this.result);
  }

  createForm(): FormGroup {
    return this.fb.group({
      card_holder: ['', [Validators.required]],
      month: ['', [Validators.required]],
      year: ['', [Validators.required]],
      card_number: ['', [Validators.required]],
      cvv: ['', [Validators.required]],
      tc: [null, [Validators.requiredTrue]],
      pp: [null, [Validators.requiredTrue]],
      subscription: [null, []]
    });
  }

  cardType(event: any): void { // Check if card is amex or visa/mastercard
    const cardNum = event.target.value;
    if (cardNum.length >= 3) {
      const cardTypeStatus = this.amexRegEx.test(cardNum);
      this.cardMask = cardTypeStatus ? this.cardMasks.typeTwo : this.cardMasks.typeOne;
      this.cvvMask = cardTypeStatus ? this.cvvMasks.typeTwo : this.cvvMasks.typeOne;
    }
  }

  makePayment(): void {
    if (this.form.valid) {
      // Se redireccionará al portal del banco emisor de su
      // tarjeta para autorizar el pago de su suscripción por el monto de $${this.amount} mxn,
      // ¿desea continuar?`,

      this.notificationService.confirmDialog(
        `Autorizar el pago de la suscripción por un monto de $${this.amount} mxn ${this.form.controls.subscription.value ? ', con renovación automática cada mes' : ''} ¿deseas continuar?`,
        'Cancelar',
        'Si',
        'Confirme acción'
      )
      .then(async (response) => {
        if (response.isConfirmed) {
          // Generate Payment
          this.paymentBtn.active = true;
          this.paymentFormMsgs = [];
          this.userPaymentData = this.form.value;
          this.openpayService.setTokenData(this.userPaymentData); // Set user payment information
          this.cardErrorMsg = this.openpayService.validateUserCard();

          if (!this.cardErrorMsg) {
            this.createPaymentToken(); // Create a card token through openpay
          }
          else {
            timer(2000).subscribe(x => {
              this.paymentFormMsgs = [];
              this.paymentFormMsgs.push(this.cardErrorMsg);
              this.paymentBtn.active = false;
            });
          }
        }
      });
    } else {
      this.paymentBtn.active = false;
      this.formValidator.allFields(this.form);
    }
  }

  async createPaymentToken(): Promise<any> {
    this.paymentBtn.active = true;
    await this.openpayService.createCardToken().then(
      (response) => {
        this.paymentData = {
          name: this.userPaymentData.card_holder,
          email: this.dataService.profileValue.email,
          card_token: response.data.id,
          td_secure: false,
          subscription: this.userPaymentData.subscription,
          device_id: this.openpayService.getDeviceSessionId(),
          total: 299
        };
        this.generateCharge();
      },
      (error) => {
        this.notificationService.warningMessage(error, 'Aviso');
        this.paymentBtn.active = false;
      }
    );
  }

  generateCharge(): void {
    this.paymentService.generatePayment(this.paymentData).subscribe(
      res => {
        if (res.status) {
          this.result = res; // set result
          // this.notificationService.successDialog('Acción completa');
          // this.dialogRef.close(res);
          // window.location.href = this.result.url;

          this.onClose();
        } else {
          this.paymentBtn.text = 'Reintentar';
          const chargeError = !res.error_code ? res.data : this.openpayService.getCardErrorMsg(res.error_code);          
          // this.notificationService.warningMessage(chargeError);
          this.paymentFormMsgs = [];
          this.paymentFormMsgs.push({ severity: 'error', summary: 'Aviso', detail: chargeError});
        }
        this.paymentBtn.active = false;
      },
      error => {
        this.paymentBtn.active = false;
        this.notificationService.warningMessage(error, 'Aviso');
      }
    );
  }

  openDoc(doc: string): void {
    window.open(doc);
  }
}
