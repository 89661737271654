import { Injectable } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import firebase from 'firebase/app';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class FirebaseAuthService {
  provider: any;

  constructor(
    public angularfireAuth: AngularFireAuth, // Inject Firebase auth service
    public authService: AuthService
  ) {}

  // Sign in with Social login
  login(providerName: string): Promise<any> {
    switch (providerName) {
      case 'google':
        this.provider = new firebase.auth.GoogleAuthProvider();
        break;
      case 'twitter':
        this.provider = new firebase.auth.TwitterAuthProvider();
        // this.provider.addScope('email');
        break;
      case 'facebook':
        this.provider = new firebase.auth.FacebookAuthProvider();
        break;
      case 'outlook':
        this.provider = new firebase.auth.OAuthProvider('microsoft.com');
        break;
      case 'apple':
        this.provider = new firebase.auth.OAuthProvider('apple.com');
        this.provider.addScope('email');
        this.provider.addScope('name');
        break;
    }
    return this.authLogin(this.provider);
  }

  // Auth logic to run auth providers
  authLogin(provider: any): Promise<any> {
    return this.angularfireAuth.signInWithPopup(provider) // Open a popup window to authenticate user
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.log('Error', error);
    });
  }

}
