import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { IGlobal } from 'src/app/interfaces/global';
import { DataService } from '../../data/data.service';
import { NotificationService } from '../../messaging/notification.service';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthService,
    private spinner: NgxSpinnerService,
    private dataService: DataService,
    private notificationService: NotificationService,
    private router: Router
    ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401) {
          this.spinner.show();
          // auto logout if 401 response returned from api
          this.authenticationService.refresh().subscribe((response: IGlobal) => {
            if (response.status) {
              this.dataService.setTokenValue(response.data);
              this.dataService.setGlobalData();
              window.location.reload();
            }
          });
          this.spinner.hide();
        }
        const error = err.error.msg || err.statusText;
        return throwError(error);
      })
    );
  }
}
