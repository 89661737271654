<section class="content">
    <div class="container-fluid">
      <div class="block-header">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <ul class="breadcrumb breadcrumb-style ">
              <li class="breadcrumb-item">
                <h4>Mi suscripción</h4>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="w-100 d-flex flex-column">
        <div class="row justify-content-md-center suscription">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="card posts-card">
              <div class="header pt-2 pb-3">
                <div class="d-flex justify-content-end">
                  <button mat-mini-fab color="primary" (click)="refreshData()">
                    <mat-icon class="col-white">refresh</mat-icon>
                  </button>
                </div>
              </div>
              <div class="card-body p-0 m-0">
                <p-messages [value]="paymentMsgs" [enableService]="false" ></p-messages>
                <ng-container *ngFor="let msg of paymentMsgs" >
                  <p-messages severity="{{msg.severity}}">
                    <ng-template pTemplate>
                        <div class="ml-1"><mat-icon class="mr-3">error</mat-icon></div>
                        <div class="ml-2"><p class="p-0 m-0" style="font-weight: 600;">AVISO</p></div>
                        <div class="ml-3 font-16">{{msg.detail}}</div>
                    </ng-template>
                  </p-messages>
                </ng-container>
  
                <app-skeleton-list *ngIf="skeletonActive; else loaded"></app-skeleton-list>
  
                <ng-template #loaded>
                  <mat-accordion *ngIf="supplier; else noContent" ngClass="posts-accordion" multi>
                      <mat-expansion-panel ngClass="expansion-posts" [expanded]="true" hideToggle>
                          <mat-expansion-panel-header ngClass="post-header">
                              <mat-panel-title ngClass="post-title align-content-center align-items-center">
                                  <mat-icon class="mr-3">payment</mat-icon>
                                  <p class="m-0 p-0 font-16 col-iparty-purple">
                                      Suscripción activa
                                  </p>
                                  <mat-icon class="col-iparty-green">check_circle</mat-icon>
                              </mat-panel-title>
                              <mat-panel-description ngClass="align-content-center align-items-center post-actions">
                                <p class="m-0 p-0 font-16 col-iparty-blue">
                                  {{ supplier.expiry_date ? 'Expira el ' + (supplier.expiry_date | date) : 'Fecha de expiración no disponible '}}
                                </p>
                              </mat-panel-description>
                              <mat-panel-description ngClass="align-content-center align-items-center post-actions">
                                <button mat-button 
                                (click)="$event.stopPropagation()"
                                (click)="openPaymentDetailDialog()">
                                    <mat-icon aria-label="Detalle" class="col-iparty-blue">info</mat-icon>
                                    <span class="detail-btn" style="color: black;"> Detalle </span>
                                </button>
                              </mat-panel-description>
                              <mat-panel-description *ngIf="showPayment" ngClass="align-content-center align-items-center post-actions">
                                <!-- <mat-panel-description ngClass="align-content-center align-items-center post-actions"> -->
                                <button mat-button 
                                (click)="$event.stopPropagation()"
                                (click)="openPaymentDialog()">
                                  <mat-icon aria-label="Detalle" class="col-iparty-red">payments</mat-icon>
                                  <span class="detail-btn" style="color: black;"> Pagar </span>
                                </button>
                              </mat-panel-description>
                              <mat-panel-description *ngIf="supplier.subscription_id && !showPayment" ngClass="align-content-center align-items-center post-actions">
                                <!-- <mat-panel-description ngClass="align-content-center align-items-center post-actions"> -->
                                <button mat-button
                                #tooltip="matTooltip"
                                matTooltip="Cacelar suscripción"
                                matTooltipPosition="right"
                                aria-label="Cacelar suscripción"
                                (click)="$event.stopPropagation()"
                                (click)="cancelSuscription()">
                                  <mat-icon aria-label="Detalle" class="col-iparty-red">cancel</mat-icon>
                                  <span class="detail-btn" style="color: black;"> Cancelar </span>
                                </button>
                              </mat-panel-description>
                              <mat-panel-description ngClass="align-content-center align-items-center justify-content-end post-actions">
                              </mat-panel-description>
                          </mat-expansion-panel-header>
  
                          <mat-divider></mat-divider>
                          
                          <div class="d-flex align-content-center align-items-center history-detail pt-3" style="font-weight: 600;">
                            <p class="description">Id de transacción</p>
                            <p class="amount">Monto</p>
                            <p class="date">Fecha</p>
                          </div>
                          <mat-divider></mat-divider>
  
                          <ng-container *ngIf="supplier && supplier.payments">
                            <ng-container *ngFor="let row of supplier.payments;" class="d-flex align-content-center align-items-center history">
                              <div class="d-flex align-content-center align-items-center history-detail pt-2">
                                <p class="description">{{row.charge_id}}</p>
                                <p class="amount">{{row.amount | currency: 'MXN'}}</p>
                                <p class="date">{{row.created_at | date: 'MMMM d, y'}}</p>
                              </div>
                              <mat-divider></mat-divider>
                            </ng-container>
                          </ng-container>
                      </mat-expansion-panel>
                  </mat-accordion>
                  
                  <ng-template #noContent>
                    <app-no-content></app-no-content>
                  </ng-template>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
      </div>
    </div>
</section>
  