import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Post } from 'src/app/core/models/post';
import { IGlobal } from 'src/app/interfaces/global';
import { IPostArrayRes, IPostRes } from 'src/app/interfaces/post';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PostsService {
  API_URL = environment.apiAuthURL;
  route = '/posts';

  constructor(
    private http: HttpClient
  ) { }

  index(): Observable<IPostArrayRes> {
    return this.http.get<IPostArrayRes>(`${this.API_URL}${this.route}`, {});
  }

  filteredPosts(): Observable<IPostArrayRes> {
    return this.http.get<IPostArrayRes>(`${this.API_URL}/other-posts`, {});
  }

  show(id: number): Observable<IPostRes> {
    return this.http.get<IPostRes>(`${this.API_URL}${this.route}/${id}`, {});
  }

  create(data: Post): Observable<IPostRes> {
    return this.http.post<IPostRes>(`${this.API_URL}${this.route}`, data);
  }

  update(data: Post): Observable<IPostRes> {
    return this.http.put<IPostRes>(`${this.API_URL}${this.route}/${data.id}`, data);
  }

  delete(id: number): Observable<IGlobal> {
    return this.http.delete<IGlobal>(`${this.API_URL}${this.route}/${id}`, {});
  }

  addResource(postId: number, file: File[]): Observable<IGlobal> { // Add images to posts
    const fd = new FormData();
    fd.append('post_id', String(postId));
    fd.append('type', 'image');
    fd.append('name', 'default');
    if (file) {
      file.forEach(item => fd.append('file', item));
    }

    return this.http.post<IGlobal>(`${this.API_URL}/resources-store`, fd, {
    });
  }

  deleteResource(resourceId: number): Observable<IGlobal> { // Delete image from post
    return this.http.delete<IGlobal>(`${this.API_URL}/resources-delete/${resourceId}`, {
    });
  }
}
