import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NotificationValidationService {

  constructor(
    private router: Router
  ) { }

  validate(noti: any): void {
    console.log(noti);
    switch (noti.title) {
      case 'Nueva pregunta':
        // calendar/:postId/:questionId
        this.router.navigate([`/sections/posts-questions/${noti.data.post_id}/${noti.id}`], { replaceUrl: true });
        break;
      case 'A un cliente le interesan tus servicios':
        // calendar/:eventId/:postId
        this.router.navigate([`/sections/calendar/${noti.event_id}/${noti.data.id}`], { replaceUrl: true });
        break;
      case 'Hay una nueva publicación por revisar':
        this.router.navigate([`sections/posts/review`], { replaceUrl: true });
        break;
      case 'Hay una nueva notificación por revisar.':
        this.router.navigate([`sections/notification/review`], { replaceUrl: true });
        break;
      case 'El proveedor respondio tu pregunta.':
        // questions/:postId/:questionId
        this.router.navigate([`/sections/questions/${noti.data.post_id}/${noti.id}`], { replaceUrl: true });
        break;
      case 'Ya puedes calificar el servicio.':
        this.router.navigate([`/sections/events`], { replaceUrl: true });
        break;
    }
  }
}
