export class Address {
    id: number;
    'main_address': string;
    address: string;
    latitude: number;
    longitude: number;
    coverage: boolean;
    'supplier_id': boolean;

    constructor(address) {
      {
        this.id = address.id || '';
        this.main_address = address.main_address || '';
        this.address = address.address || '';
        this.latitude = address.latitude || '';
        this.longitude = address.longitude || '';
        this.coverage = address.coverage || 5;
        this.supplier_id = address.supplier_id || '';
      }
    }
}
