<div class="address-form addContainer pb-0 p-4">
    <div class="modalHeader">
        <div class="editRowModal">
        <div class="modalHeader clearfix">
            <div class="modal-about">
                <div class="font-weight-bold p-t-5 font-17">
                    {{dialogTitle}}
                </div>
            </div>
        </div>
        </div>
        <button mat-icon-button (click)="dialogRef.close()" aria-label="Cerrar">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div mat-dialog-content>
        <div>
            <div class="font-weight-bold p-t-5 pl-3 font-15">
                Ubica tu dirección en el mapa
            </div>
            <div class="maps p-2">
                <app-maps [isUpdate]="this.action === 'edit'? true : false" [markerInput]="marker"></app-maps>
            </div>
            <div class="font-weight-bold pt-2 pl-4 font-15">
                Dirección
            </div>
            <div class="address-form">
                <form class="register-form ml-4 mr-4" [formGroup]="form">
                    <div class="row">
                        <div class="col-xl-12 col-md-12 col-sm-12 mb-2 address-input">
                            <mat-form-field class="default-full-width" appearance="outline">
                                <!-- <mat-label>Dirección completa</mat-label> -->
                                <textarea matInput formControlName="address"></textarea>
                                <mat-error *ngFor="let validation of validationMessages.address">
                                    <span *ngIf="formValidatorService.field(form, validation.type, 'address')">
                                        {{ validation.message }}
                                    </span>
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <!-- <div class="col-4 mb-2">
                            <mat-form-field class="default-full-width" appearance="outline">
                                <mat-label>Latitud</mat-label>
                                <input matInput formControlName="latitude">
                                <mat-icon matSuffix>location_on</mat-icon>
                            </mat-form-field>
                        </div>
                        <div class="col-4 mb-2">
                            <mat-form-field class="default-full-width" appearance="outline">
                                <mat-label>Longitud</mat-label>
                                <input matInput formControlName="longitude">
                                <mat-icon matSuffix>location_on</mat-icon>
                            </mat-form-field>
                        </div> -->
                        <div class="col-4 mb-2 coverage-input">
                            <mat-form-field class="default-full-width" appearance="outline">
                                <mat-label>Cobertura</mat-label>
                                <input matInput type="number" formControlName="coverage">
                                <span matSuffix>
                                    km
                                </span>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end p-0 m-0">
                        <button mat-stroked-button color="default" class="mr-3" (click)="onNoClick()" tabindex="-1">Cancelar</button>
                        <button mat-raised-button color="primary"
                        (click)="save()">Guardar</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
  