<div class="addContainer posts-container-form">
    <div class="modalHeader">
        <div class="editRowModal">
            <div class="modalHeader clearfix">
                <div class="modal-about">
                <div class="font-weight-bold p-t-5 font-17">
                    {{dialogTitle}}</div>
                </div>
            </div>
        </div>
        <button mat-icon-button (click)="onNoClick()" aria-label="Cerrar">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div mat-dialog-content>
        <mat-horizontal-stepper #stepper ngClass="stepper" (selectionChange)="onStepChange($event)">
            <mat-step [stepControl]="postForm" state="general">
                <ng-template matStepLabel>Publicación</ng-template>
                <div class="container mt-3">
                    <form [formGroup]="postForm">
                        <div class="row">
                            <div class="col-6 mb-2">
                                <mat-form-field class="default-full-width" appearance="outline">
                                    <mat-label>Categoría</mat-label>
                                    <mat-select formControlName="category_id" (selectionChange)="categoryChanged($event)">
                                        <mat-option *ngFor="let category of categories" [value]="category.id">
                                            {{category.name}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngFor="let validation of validationMessages.category_id">
                                        <span *ngIf="formValidatorService.field(postForm, validation.type, 'category_id')">
                                            {{ validation.message }}
                                        </span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-6 mb-2">
                                <mat-form-field class="default-full-width" appearance="outline">
                                    <mat-label>Subcategoría</mat-label>
                                    <mat-select formControlName="subcategory_id">
                                        <mat-option *ngFor="let subCategory of filteredSubCategories" [value]="subCategory.id">
                                            {{subCategory.name}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngFor="let validation of validationMessages.subcategory_id">
                                        <span *ngIf="formValidatorService.field(postForm, validation.type, 'subcategory_id')">
                                            {{ validation.message }}
                                        </span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 mb-2">
                                <mat-form-field class="default-full-width" appearance="outline">
                                    <mat-label>Nombre</mat-label>
                                    <input matInput formControlName="name">
                                    <mat-error *ngFor="let validation of validationMessages.name">
                                        <span *ngIf="formValidatorService.field(postForm, validation.type, 'name')">
                                            {{ validation.message }}
                                        </span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-12 mb-2">
                                <mat-form-field class="default-full-width" appearance="outline">
                                    <mat-label>Descripción</mat-label>
                                    <textarea matInput formControlName="description"></textarea>
                                    <mat-error *ngFor="let validation of validationMessages.description">
                                        <span *ngIf="formValidatorService.field(postForm, validation.type, 'description')">
                                            {{ validation.message }}
                                        </span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </form>
                    <div class="d-flex justify-content-end pb-1">
                        <button mat-raised-button color="primary" matStepperNext>Siguiente</button>
                    </div>
                </div>
            </mat-step>
            <mat-step [stepControl]="detailForm" state="money">
                <ng-template matStepLabel>Detalle</ng-template>
                <div class="container mt-3">
                    <form [formGroup]="detailForm">
                        <div class="row">
                            <!--div class="col-5 mb-2">
                                <mat-form-field class="default-full-width" appearance="outline" hidden>
                                    <mat-label>Fecha de expiración</mat-label>
                                    <input matInput [min]="minDate" [matDatepicker]="picker" formControlName="expiry_date"
                                    (click)="picker.open()" readonly>
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                    <mat-error *ngFor="let validation of validationMessages.expiry_date">
                                        <span *ngIf="formValidatorService.field(detailForm, validation.type, 'expiry_date')">
                                            {{ validation.message }}
                                        </span>
                                    </mat-error>
                                </mat-form-field>
                            </div-->
                            <div class="col-4 mb-2">
                                <mat-form-field class="default-full-width price-field" appearance="outline">
                                    <mat-label class="price-label">Precio</mat-label>
                                    <span matPrefix>
                                        <mat-icon style="width: auto; margin-left: -8px;">attach_money</mat-icon>
                                    </span>
                                    <input matInput placeholder="" type="number" formControlName="price"/>
                                    <mat-error *ngFor="let validation of validationMessages.price">
                                        <span *ngIf="formValidatorService.field(detailForm, validation.type, 'price')">
                                            {{ validation.message }}
                                        </span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-3 mb-2">
                                <mat-form-field class="default-full-width" appearance="outline">
                                    <mat-label>Descuento</mat-label>
                                    <input matInput type="number" formControlName="discount">
                                    <span matSuffix>
                                        <i class="fas fa-percent"></i>
                                    </span>
                                    <mat-error *ngFor="let validation of validationMessages.discount">
                                        <span *ngIf="formValidatorService.field(detailForm, validation.type, 'discount')">
                                            {{ validation.message }}
                                        </span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row" *ngIf="detailForm.controls.discount.value > 0">
                            <div class="col mb-4">
                                <mat-form-field class="default-chip-list default-full-width" appearance="outline">
                                    <mat-chip-list #chipList aria-label="Días">
                                    <mat-chip *ngFor="let day of discountDays; let indx=index;"  [selectable]="selectable" [removable]="removable"
                                        (removed)="removeDiscountDay(day, indx)">
                                        {{day.name}}
                                        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                    </mat-chip>
                                    <input placeholder="Días que se aplicará descuento" #discountDayInput formControlName="discount_days" [matAutocomplete]="auto"
                                        [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                        (matChipInputTokenEnd)="addDiscountDay($event)">
                                    </mat-chip-list>
                                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedDiscountDay($event)">
                                    <mat-option *ngFor="let day of filteredDiscountDays | async" [value]="day">
                                        {{day.name}}
                                    </mat-option>
                                    </mat-autocomplete>
                                    <mat-hint>Si no selecciona ningún día, el descuento se aplicará para todos los días</mat-hint>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row d-flex align-items-center">
                            <div class="col-3 mb-2">
                                <div class="m-b-20">
                                    <section class="toggle-section">
                                    <mat-slide-toggle class="" [color]="'default'" formControlName="visible">
                                        Visible
                                    </mat-slide-toggle>
                                    </section>
                                </div>
                            </div>
                            <div class="col-6 mb-2">
                                <div class="m-b-20">
                                    <section class="toggle-section">
                                    <mat-slide-toggle class="" [color]="'default'" formControlName="required_limit"
                                    (change)="onRequiredLimitChange()">
                                        Límite de agendados
                                    </mat-slide-toggle>
                                    </section>
                                </div>
                            </div>
                            <div *ngIf="detailForm.controls.required_limit.value" class="col-3">
                                <mat-form-field class="default-full-width" appearance="outline">
                                    <mat-label>Cantidad</mat-label>
                                    <input matInput placeholder="" type="number" formControlName="stock"/>
                                    <mat-error *ngFor="let validation of validationMessages.stock">
                                        <span *ngIf="formValidatorService.field(detailForm, validation.type, 'stock')">
                                            {{ validation.message }}
                                        </span>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </form>
                    <div class="d-flex justify-content-end pb-1">
                        <button mat-raised-button class="mr-2" matStepperPrevious>Atrás</button>
                        <button mat-raised-button color="primary" matStepperNext>Siguiente</button>
                    </div>
                </div>
            </mat-step>
            <mat-step [stepControl]="addressForm" state="address">
                <ng-template matStepLabel>Dirección</ng-template>
                <div class="container mt-3">
                    <form [formGroup]="addressForm">
                        <div class="row d-flex align-items-center">
                            <div class="col-12 mb-4 address-container">
                                <mat-accordion hideToggle>
                                    <mat-expansion-panel [expanded]="panelOpenState" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                                      <mat-expansion-panel-header>
                                        <mat-panel-title>
                                          Dirección
                                        </mat-panel-title>
                                        <mat-panel-description>
                                            <p *ngIf="!address" class="m-0 p-0">No ha seleccionado una dirección</p>
                                        </mat-panel-description>
                                      </mat-expansion-panel-header>
                                      <div class="row">
                                        <div *ngIf="address" class="col mb-2 d-flex align-content-center flex-wrap">
                                            <p class="m-0 p-0">{{address.address}}</p>
                                        </div>
                                        <div class="col mb-2">
                                            <button mat-raised-button color="default"
                                            class="mr-2"
                                            (click)="openAddressDialog()">
                                                <i class="fas fa-address-card"></i>
                                                {{addressForm.controls.address_id.value? 'Cambiar dirección' : 'Asignar dirección'}}
                                            </button>
                                            <button *ngIf="addressForm.controls.address_id.value" 
                                            mat-raised-button color="default"
                                            class="p-0 m-0"
                                            style="min-width: 40px;"
                                            (click)="confirmDelAddress()">
                                                <i class="fas fa-trash"></i>
                                            </button>
                                        </div>
                                      </div>
                                    </mat-expansion-panel>
                                    <mat-error *ngFor="let validation of validationMessages.address_id">
                                        <span *ngIf="formValidatorService.field(addressForm, validation.type, 'address_id')">
                                            {{ validation.message }}
                                        </span>
                                    </mat-error>
                                </mat-accordion>
                            </div>
                        </div>
                    </form>
                    <div class="d-flex justify-content-end pb-1">
                        <button mat-raised-button class="mr-2" matStepperPrevious>Atrás</button>
                        <button mat-raised-button color="primary" matStepperNext>Siguiente</button>
                    </div>
                </div>
            </mat-step>
            <mat-step [stepControl]="resourceForm" state="image">
                <ng-template matStepLabel>Imágenes</ng-template>
                <div class="container mt-3">

                    <div class="d-flex align-items-center col-12">
                        <form [formGroup]="resourceForm" 
                        class="d-flex flex-column center-row p-0 m-0 col-12">
                            <div class="d-flex col-12">
                                <div class="col-6 box-image-add separate-box" [ngClass]="dataSource.resources.length === 1 ? 'one-image' : ''">
                                    <div class="row separate">
                                        <ng-container *ngIf="dataSource.resources[0] else localResourceZero">
                                            <img
                                                [style.height]="'180px'"
                                                class="server-img"
                                                [src]="dataService.postImageURL(dataSource.resources[0].post_id, dataSource.resources[0].name)">
                                            <div *ngIf="dataSource.resources.length > 1" class="img-remove-area">
                                                <mat-spinner-button
                                                    class="btn-remove-img"
                                                    [options]="spinnerButtonOptions"
                                                    (btnClick)="deleteResource(dataSource.resources[0].id, 0)">
                                                </mat-spinner-button>
                                            </div>
                                        </ng-container>
                                        <ng-template #localResourceZero>
                                            <div *ngIf="resourcesUris[0] == null else elseFileZero" class="add-img-icon-area">
                                                <img 
                                                    #imgOne
                                                    src="/assets/images/add.svg"
                                                    class="center expand-collapse"
                                                    (click)="uploader($event, imgOne, 0)"
                                                    pTooltip="Agregar imagen">
                                            </div>
                                                <ng-template #elseFileZero>
                                                    <img [src]="resourcesUris[0]" class="local-img">
                                                    <mat-spinner-button
                                                    class="btn-remove-img mt-2"
                                                    [options]="spinnerButtonOptions"
                                                    (btnClick)="deleteNewResource(0)">
                                                    </mat-spinner-button>
                                                </ng-template>
                                        </ng-template>
                                    </div>
                                </div>
                                <div class="col-6 box-image-add separate-box" [ngClass]="dataSource.resources.length === 1 ? 'one-image' : ''">
                                    <div class="row separate">
                                        <ng-container *ngIf="dataSource.resources[1] else localResourceOne">
                                            <img
                                                class="server-img"
                                                [src]="dataService.postImageURL(dataSource.resources[1].post_id, dataSource.resources[1].name)">
                                            <div class="img-remove-area">
                                                <mat-spinner-button
                                                    class="btn-remove-img"
                                                    [options]="spinnerButtonOptions"
                                                    (btnClick)="deleteResource(dataSource.resources[1].id, 1)">
                                                </mat-spinner-button>
                                            </div>
                                        </ng-container>
                                        <ng-template #localResourceOne>
                                            <div *ngIf="resourcesUris[1] == null else elseFileOne" class="add-img-icon-area">
                                                <img 
                                                    #imgTwo
                                                    src="/assets/images/add.svg"
                                                    class="center expand-collapse"
                                                    (click)="uploader($event, imgTwo, 1)"
                                                    pTooltip="Agregar imagen">
                                            </div>
                                            <ng-template #elseFileOne>
                                                <img [src]="resourcesUris[1]" class="local-img">
                                                <mat-spinner-button
                                                class="btn-remove-img mt-2"
                                                [options]="spinnerButtonOptions"
                                                (btnClick)="deleteNewResource(1)">
                                                </mat-spinner-button>
                                            </ng-template>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex col-12">
                                <div class="col-6 box-image-add separate-box" [ngClass]="dataSource.resources.length === 1 ? 'one-image' : ''">
                                    <div class="row separate">
                                        <ng-container *ngIf="dataSource.resources[2] else localResourceTwo">
                                            <img
                                                class="server-img"
                                                [src]="dataService.postImageURL(dataSource.resources[2].post_id, dataSource.resources[2].name)">
                                            <div class="img-remove-area">
                                                <mat-spinner-button
                                                    class="btn-remove-img"
                                                    [options]="spinnerButtonOptions"
                                                    (btnClick)="deleteResource(dataSource.resources[2].id, 2)">
                                                </mat-spinner-button>
                                            </div>
                                        </ng-container>
                                        <ng-template #localResourceTwo>
                                            <div *ngIf="resourcesUris[2] == null else elseFileTwo" class="add-img-icon-area">
                                                <img 
                                                    #imgThree
                                                    src="/assets/images/add.svg"
                                                    class="center expand-collapse"
                                                    (click)="uploader($event, imgThree, 2)"
                                                    pTooltip="Agregar imagen">
                                            </div>
                                            <ng-template #elseFileTwo>
                                                <img [src]="resourcesUris[2]" class="local-img">
                                                <mat-spinner-button
                                                class="btn-remove-img mt-2"
                                                [options]="spinnerButtonOptions"
                                                (btnClick)="deleteNewResource(2)">
                                                </mat-spinner-button>
                                            </ng-template>
                                        </ng-template>
                                    </div>
                                </div>
                                <div class="col-6 box-image-add separate-box" [ngClass]="dataSource.resources.length === 1 ? 'one-image' : ''">
                                    <div class="row separate">
                                        <ng-container *ngIf="dataSource.resources[3] else localResourceThree">
                                            <img
                                                class="server-img"
                                                [src]="dataService.postImageURL(dataSource.resources[3].post_id, dataSource.resources[3].name)">
                                            <div class="img-remove-area">
                                                <mat-spinner-button
                                                    class="btn-remove-img"
                                                    [options]="spinnerButtonOptions"
                                                    (btnClick)="deleteResource(dataSource.resources[3].id, 3)">
                                                </mat-spinner-button>
                                            </div>
                                        </ng-container>
                                        <ng-template #localResourceThree>
                                            <div *ngIf="resourcesUris[3] == null else elseFileThree" class="add-img-icon-area">
                                                <img 
                                                    #imgFour
                                                    src="/assets/images/add.svg"
                                                    class="center expand-collapse"
                                                    (click)="uploader($event, imgFour, 3)"
                                                    pTooltip="Agregar imagen">
                                            </div>
                                            <ng-template #elseFileThree>
                                                <img [src]="resourcesUris[3]" class="local-img">
                                                <mat-spinner-button
                                                    class="btn-remove-img mt-2"
                                                    [options]="spinnerButtonOptions"
                                                    (btnClick)="deleteNewResource(3)">
                                                </mat-spinner-button>
                                            </ng-template>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                            
                            <input #uploadFile type="file" accept="image/png, image/jpg, image/jpeg" (change)="fileChangeEvent($event)" class="hide" />
                            
                            <p-dialog
                                [(visible)]="display"
                                [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
                                [style]="{width: '1000px'}"
                                [dismissableMask]="dismissableMask"
                                [closable]="closable">
                                <ng-template pTemplate="header">
                                    <h4>Ajustar la imagen</h4>
                                </ng-template>
                                <ng-template pTemplate="content">
                                    <image-cropper-dialog
                                        [imageChangedEvent]="imageChangedEvent"
                                        [addFile]="addFile"
                                        [indexFile]="indexFile"
                                        (saveResource)="saveResource($event)">
                                    </image-cropper-dialog>
                                </ng-template>
                                <ng-template pTemplate="footer">
                                    <button mat-raised-button class="mr-2" (click)="hideCropperDialog()">Cancelar</button>
                                    <button mat-raised-button color="primary" class="mr-2" (click)="addToResources()">Agregar</button>
                                </ng-template>
                            </p-dialog>
                        </form>
                    </div>
                    <div class="d-flex justify-content-end pb-1 separate-area">
                        <button mat-raised-button class="mr-2" matStepperPrevious>Atrás</button>
                        <mat-spinner-button
                            type="button"
                            ngClass="spinner-btn mr-3"
                            matStepperNext
                            [options]="saveBtn"
                            (click)="save()">
                        </mat-spinner-button>
                    </div>
                </div>
            </mat-step>

              <!-- Icon overrides. -->
            <ng-template matStepperIcon="general">
                <mat-icon>description</mat-icon>
            </ng-template>
            <ng-template matStepperIcon="address">
                <mat-icon>place</mat-icon>
            </ng-template>
            <ng-template matStepperIcon="image">
                <mat-icon>photo</mat-icon>
            </ng-template>
            <ng-template matStepperIcon="money">
                <mat-icon>attach_money</mat-icon>
            </ng-template>
        </mat-horizontal-stepper>
    </div>
</div>
  