import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Address } from 'src/app/core/models/address';
import { IAddressArrayRes, IAddressRes } from 'src/app/interfaces/address';
import { IGlobal } from 'src/app/interfaces/global';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AddressesService {
  API_URL = environment.apiAuthURL;
  route = '/address';
  constructor(
    private http: HttpClient
  ) { }

  index(): Observable<IAddressArrayRes> {
    return this.http.get<IAddressArrayRes>(`${this.API_URL}${this.route}`, {});
  }

  create(data: Address): Observable<IAddressRes> {
    return this.http.post<IAddressRes>(`${this.API_URL}${this.route}`, data);
  }

  update(data: Address): Observable<IAddressRes> {
    return this.http.put<IAddressRes>(`${this.API_URL}${this.route}/${data.id}`, data);
  }

  updateMainAddress(id: number): Observable<IAddressRes> {
    return this.http.put<IAddressRes>(`${this.API_URL}${this.route}/${id}`, {
      action: 'main_address'
    });
  }

  delete(id: number): Observable<IGlobal> {
    return this.http.delete<IGlobal>(`${this.API_URL}${this.route}/${id}`, {});
  }
}
