import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IIconRes } from 'src/app/interfaces/resource';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ResourcesService {
  API_URL = environment.apiURL;
  route = 'image/';

  constructor(
    private http: HttpClient
  ) { }

  icons(): Observable<IIconRes> {
    return this.http.get<IIconRes>(`${this.API_URL}${this.route}`, {});
  }
}
