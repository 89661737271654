import { NgModule } from '@angular/core';
import { FileUploadModule } from 'primeng/fileupload';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { SkeletonModule } from 'primeng/skeleton';
import { DialogModule } from 'primeng/dialog';
import { TooltipModule } from 'primeng/tooltip';
import { RatingModule } from 'primeng/rating';

const modules = [
  FileUploadModule,
  SkeletonModule,
  MessagesModule,
  MessageModule,
  DialogModule,
  TooltipModule,
  RatingModule
];

@NgModule({
  declarations: [],
  imports: [
    modules
  ],
  exports: [
    modules
  ]
})
export class PrimengModule { }
