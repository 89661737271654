import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Address } from 'src/app/core/models/address';
import { FormDialogComponent } from 'src/app/sections/admin/user/dialogs/form-dialog/form-dialog.component';

@Component({
  selector: 'app-address-dialog',
  templateUrl: './address-dialog.component.html',
  styleUrls: ['./address-dialog.component.sass']
})
export class AddressDialogComponent {
  selectAddress = true;

  constructor(
    public dialogRef: MatDialogRef<FormDialogComponent>
    ) { }

  selectedAddress(event: Address): void {
    this.dialogRef.close(event);
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }
}
