import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ISupplierData } from 'src/app/interfaces/user-auth';

@Component({
  selector: 'app-payment-detail',
  templateUrl: './payment-detail.component.html',
  styleUrls: ['./payment-detail.component.sass']
})
export class PaymentDetailComponent implements OnInit {
  dialogTitle: string;
  suscriptionEndDate = '2022/02/28';
  supplier: ISupplierData;

  constructor(
    public dialogRef: MatDialogRef<PaymentDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
  ) {
    this.dialogTitle = 'Detalle de suscripción';
    this.supplier = data;
  }

  ngOnInit(): void {
  }

  onClose(): void {
    this.dialogRef.close();
  }

}
