<div class="limiter">
    <div class="container no-content-container">
        <div class="img-container d-flex justify-content-center">
            <img class="" width="400" src="assets/images/other/no-content.png">
        </div>
        <div class="content">
            <span class="header p-b-35">
                Sin datos
            </span>
            <span class="subheader p-b-5">
                No existe información que mostrar
            </span>
        </div>
    </div>
  </div>
  