import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Inject, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AlertService } from 'src/app/services/alert/alert.service';
import { DataService } from 'src/app/services/data/data.service';
import { Address } from 'src/app/core/models/address';
import { Categories } from 'src/app/core/models/categories';
import { Post } from 'src/app/core/models/post';
import { SubCategories } from 'src/app/core/models/sub-categories';
import { IGlobal } from 'src/app/interfaces/global';
import { PostsService } from 'src/app/services/supplier/posts.service';
import { AddressDialogComponent } from '../address-dialog/address-dialog.component';
import { FormValidatorService } from 'src/app/services/validator/form-validator.service';

@Component({
  selector: 'app-form-dialog',
  templateUrl: './form-dialog.component.html',
  styleUrls: ['./form-dialog.component.sass']
})
export class FormDialogComponent implements OnDestroy {
  postForm: FormGroup;
  detailForm: FormGroup;
  addressForm: FormGroup;
  resourceForm: FormGroup;

  action: string;
  dialogTitle: string;
  edit: boolean;

  dataSource: Post;
  result: IGlobal;
  minDate: Date;
  maxDate: Date;

  categories: Categories[];
  subCategories: SubCategories[];
  filteredSubCategories: SubCategories[];

  address: Address;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  selectable = true;
  removable = true;

  selectedTab = 0;

  spinnerButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Eliminar',
    spinnerSize: 18,
    raised: false,
    stroked: true,
    buttonColor: 'warn',
    spinnerColor: 'accent',
    fullWidth: false,
    disabled: false,
    customClass: 'spinner-btn',
    mode: 'indeterminate',
    buttonIcon: {
      fontIcon: 'delete'
    }
  };

  saveBtn: MatProgressButtonOptions = {
    active: false,
    text: 'Guardar',
    spinnerSize: 18,
    raised: true,
    stroked: false,
    buttonColor: 'primary',
    spinnerColor: 'accent',
    fullWidth: true,
    disabled: false,
    customClass: '',
    mode: 'indeterminate'
  };

  allDiscountDays: any = [
    {name: "Domingo" , value: 1},
    {name: "Lunes" , value: 2},
    {name: "Martes" , value: 3},
    {name: "Miércoles" , value: 4},
    {name: "Jueves" , value: 5},
    {name: "Viernes" , value: 6},
    {name: "Sábado" , value: 7}
  ];
  discountDays: {name: string, value: number}[] = [];
  filteredDiscountDays: Observable<string[]>;

  panelOpenState = false;

  files: any;
  fileLimit = 4;
  chooseLabel = 'Elegir imágenes';
  showFileSelector = true;
  saving = false;

  saveResourcesFailed = false;

  validationMessages = {
    name: [{ type: 'required', message: 'Ingresa un nombre para la publicación' }],
    description: [{ type: 'required', message: 'Ingresa una descripción' }],
    category_id: [{ type: 'required', message: 'Selecciona una categoría' }],
    subcategory_id: [{ type: 'required', message: 'Selecciona una subcategoría' }],
    /*expiry_date: [
      { type: 'required', message: 'Selecciona una fecha de expiración' },
      { type: 'matDatepickerMin', message: 'La fecha no puede ser menor a la fecha actual'}
    ],*/
    price: [
      { type: 'required', message: 'Ingresa el precio de tu servicio' },
      { type: 'min', message: 'El precio no puede ser menor a $1 peso' },
    ],
    discount: [
      { type: 'required', message: 'Ingresa descuento' },
      { type: 'min', message: 'El descuento no puede ser negativo' }
    ],
    stock: [
      { type: 'required', message: 'Debes agregar un stock disponible' },
      { type: 'min', message: 'La cantidad mínima es 1' }
    ],
    address_id: [{ type: 'required', message: 'Selecciona una dirección' }],
    resource: [{ type: 'required', message: 'Seleccione al menos una imagen' }]
  };

  display = false;
  imageChangedEvent: any = '';
  dismissableMask = false;
  closable = false;
  resources: Array<File> = [null, null, null, null];
  resourcesUris: Array<string> = [null, null, null, null];
  addFile = false;
  indexFile = 0;

  @ViewChild('discountDayInput') discountDayInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  @ViewChild('uploadFile') uploadFile: any; // File selector

  constructor(
    public dialogRef: MatDialogRef<FormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private formValidatorService: FormValidatorService,
    private notificationService: AlertService,
    private dataService: DataService,
    private postsService: PostsService
  ) {
    // Set the defaults
    const currentYear = new Date().getFullYear();
    this.minDate = new Date();
    this.maxDate = new Date(currentYear + 5, 11, 31);

    this.categories = this.dataService.categoriesValue;
    this.subCategories = this.dataService.subCategoriesValue;

    this.action = data.action;

    this.result = {} as IGlobal;
    this.result.status = false;

    if (this.action === 'edit') {
      this.edit = true;
      this.dialogTitle = 'Editar publicación';
      this.dataSource = data.data;
      this.result.data = data.data;
      this.address = this.dataSource.address;

      this.fileLimit = this.fileLimit - this.dataSource.resources.length;
      this.setDiscountDaysChips(this.dataSource.discount_days);
      this.categoryChanged({value: this.dataSource.subcategory ?  this.dataSource.subcategory.category_id : this.dataSource.category_id});
    } else {
      this.dialogTitle = 'Nueva publicación';
      this.dataSource = new Post({});
      this.dataSource.supplier_id = this.dataService.profileValue.supplier.id;
    }

    this.panelOpenState = true;
    this.createForm();

    this.filteredDiscountDays = this.detailForm.controls.discount_days.valueChanges.pipe(
      startWith(null),
      map((day: string | null) => day ? this._filterDiscountDays(day) : this.allDiscountDays.slice()));
  }

  ngOnDestroy(): void {
    this.onNoClick();
  }

  createForm(): void {
    this.postForm = this.fb.group({
      id: [this.dataSource.id],
      name: [this.dataSource.name, [Validators.required]],
      description: [this.dataSource.description, []],
      approved: [this.dataSource.approved, []],
      supplier_id: [this.dataSource.supplier_id, []],
      category_id: [this.dataSource.subcategory ?
         this.dataSource.subcategory.category_id : this.dataSource.category_id, [Validators.required]],
      subcategory_id: [this.dataSource.subcategory_id, [Validators.required]]
    });

    this.detailForm = this.fb.group({
      /*expiry_date: [
        this.edit ? new Date(this.dataService.getDateNoTzString(this.dataSource.expiry_date)) : this.dataSource.expiry_date,
        [Validators.required]
      ],*/
      price: [{value: this.dataSource.price, disabled: this.edit}, [this.edit ? '' : Validators.required, Validators.min(0.1)]],
      discount: [this.dataSource.discount, [Validators.min(0)]],
      discount_days: ['', []],
      required_limit: [this.dataSource.required_limit, []],
      stock: [this.dataSource.stock, []],
      visible: [this.dataSource.visible, []],
    });

    this.addressForm = this.fb.group({
      address_id: [this.dataSource.address_id, []]
    });

    this.resourceForm = this.fb.group({
      file: [false, [Validators.required]]
    });
  }

  onNoClick(): void {
    this.dialogRef.close(this.result);
  }

  categoryChanged(event: any): void {
    this.filteredSubCategories = this.subCategories.filter(item => item.category_id === event.value);
  }

  addDiscountDay(event: any): void {
    const input = event.input;
    if (input) {
      input.value = '';
    }

    this.detailForm.controls.discount_days.setValue(null);
  }

  onRequiredLimitChange(): void {
    if (this.detailForm.get('required_limit').value) {
      this.detailForm.get('stock').setValidators([, Validators.min(1)]);
    } else {
      this.detailForm.get('stock').reset();
      this.detailForm.get('stock').setErrors(null);
    }
    this.detailForm.markAsUntouched();
    this.detailForm.updateValueAndValidity();
  }

  onStepChange(event: any): void {
    switch (event.previouslySelectedIndex) {
      case 0:
        this.formValidatorService.allFields(this.postForm);
        break;
      case 1:
        this.formValidatorService.allFields(this.detailForm);
        break;
      case 2:
        this.formValidatorService.allFields(this.addressForm);
        break;
      case 3:
        this.formValidatorService.allFields(this.resourceForm);
        break;
    }
  }

  uploader(event: any, el: any, index: number): void {
    this.uploadFile.nativeElement.click();
    this.indexFile = index;
  }

  fileChangeEvent(event: any): void {
    if (this.uploadFile.nativeElement.files.length > 0) {
      this.openCropperDialog(event);
    }
  }

  addToResources(): void {
    this.addFile = true;
  }

  saveResource(event: any): void {
    this.display = false;
    this.addFile = false;
    this.resources.splice(event.index, 1, event.file);
    this.resourcesUris.splice(event.index, 1, event.uri);
  }

  removeDiscountDay(value: any, idx: number): void {
    this.discountDays.splice(idx, 1);
    this.allDiscountDays.push(value);
    this.allDiscountDays.sort((a, b) => (a.value > b.value) ? 1 : -1);

    this.discountDayInput.nativeElement.value = '';
    this.detailForm.controls.discount_days.setValue(null);
  }

  selectedDiscountDay(event: MatAutocompleteSelectedEvent): void {
    const index = this.allDiscountDays.indexOf(event.option.value);
    this.allDiscountDays.splice(index, 1);

    this.discountDays.push(event.option.value);
    this.discountDayInput.nativeElement.value = '';
    this.detailForm.controls.discount_days.setValue(null);
  }

  private setDiscountDays(): string {
    let days: any = this.discountDays.length >= 1 ?
      this.discountDays.map( day => day.value) : this.allDiscountDays.map( day => day.value);
    days.sort((a, b) => (a > b) ? 1 : -1);
    return days = days.join(",");
  }

  private setDiscountDaysChips(item: string): void {
    const daysArray = item ? item.split(',') : [];
    this.allDiscountDays = this.allDiscountDays.filter(i => {
      if (daysArray.includes(String(i.value))) {
        this.discountDays.push(i);
      }
      return !daysArray.includes(String(i.value));
    });
  }

  private _filterDiscountDays(value: any): any[] {
    const val = value.name ? value.name : value;
    return this.allDiscountDays.filter(day => day.name.toLowerCase().includes(val.toLowerCase()));
  }

  private deleteResource(resourceId: number, resourceIdx: number): void {
    this.notificationService.confirmDialog(
      'Esta acción es irreversible',
      'Cancelar',
      'Si',
      '¿Estás seguro de eliminar ésta imágen?'
    )
    .then(async (res) => {
      if (res.isConfirmed) {
        this.spinnerButtonOptions.active = true;
        const result = await this.delResource(resourceId);
        this.spinnerButtonOptions.active = false;

        if (result) {
          this.dataSource.resources.splice(resourceIdx, 1);
          this.result.data = this.dataSource;
          this.fileLimit++;
          this.notificationService.successDialog('La imágen se eliminó correctamente', 'Aviso');
        }
      }
    });
  }

  deleteNewResource(index: number): void {
    this.resources[index] = null;
    this.resourcesUris[index] = null;
  }

  public save(): void {
    this.detailForm.value.expiry_date = "2060-01-04"
    if (this.postForm.valid && this.detailForm.valid && this.addressForm.valid && this.resourceForm.valid) {
      console.log("Valor", this.detailForm.value)
      this.files = null;
      this.files = this.resources ? this.resources.filter(file => file != null) : [];

      if (this.files.length >= 1 && this.files.length <= this.fileLimit) {
        this.resourceForm.controls.file.setValue(true);
      } else if (this.files.length > this.fileLimit) {
        this.resourceForm.controls.file.setValue(false);
        this.notificationService.warningMessage('Debe eliminar imágenes, solo se permiten 4', 'Aviso');
        return;
      } else if (this.dataSource.resources.length === 0 && this.files.length === 0) {
        this.notificationService.warningMessage('Debe seleccionar al menos 1 imágen', 'Aviso');
        this.resourceForm.controls.file.setValue(false);
        return;
      }

      if (this.saveResourcesFailed) {
        this.addResource(this.result.data.id, this.files);
        return;
      }

      const postData: Post = Object.assign(this.postForm.value, this.detailForm.value,
         this.addressForm.value);
      postData.resources = this.dataSource.resources;

      this.dataSource = postData;
      this.dataSource.discount_days = this.detailForm.controls.discount.value > 0 ? this.setDiscountDays() : '';
      this.dataSource.stock = this.dataSource.required_limit ? this.dataSource.stock : null;

      if (this.action === 'edit') {
        this.dataSource.price = this.detailForm.controls.price.value;
        this.updateItem(this.dataSource);
      } else {
        this.addItem(this.dataSource);
      }
    } else {
      this.formValidatorService.allFields(this.postForm);
      this.formValidatorService.allFields(this.detailForm);
      this.formValidatorService.allFields(this.addressForm);
      this.formValidatorService.allFields(this.resourceForm);
      this.notificationService.warningMessage('Verifica la información marcada en rojo', 'Aviso');
    }
  }

  openAddressDialog(): void {
    const dialogRef = this.dialog.open(AddressDialogComponent, {
      data: {},
      width: '650px',
      maxHeight: '85vh'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.address = result;
        this.addressForm.controls.address_id.setValue(this.address.id);
      }
    });
  }

  confirmDelAddress(): void {
    this.notificationService.confirmDialog(
      '',
      'No',
      'Si',
      '¿Eliminar dirección?'
    )
    .then((result) => {
      if (result.isConfirmed) {
        this.address = null;
        this.addressForm.controls.address_id.setValue(null);
      }
    });
  }

  openCropperDialog(event: any): void {
    this.display = true;
    this.imageChangedEvent = event;
  }

  hideCropperDialog(): void {
    this.display = false;
  }

  addItem(data: Post): void {
    this.saveBtn.active = true;
    this.postsService.create(data).subscribe(
      res => {
        if (res.status) {
          this.result = res;
          if (this.files.length > 0) {
            this.addResource(this.result.data.id, this.files);
          } else {
            this.notificationService.successDialog('Publicación almacenada correctamente', 'Aviso');
            this.onNoClick();
          }
        } else {
          this.notificationService.warningMessage('Ocurrió un error al guardar la publicación', 'Aviso');
          this.saveBtn.active = false;
        }
      },
      error => {
        this.saveBtn.active = false;
        this.notificationService.warningMessage(error, 'Aviso');
      }
    );
  }

  updateItem(data: Post): void {
    this.saveBtn.active = true;
    this.postsService.update(data).subscribe(
      res => {
        if (res.status) {
          this.result = res;
          if (this.files.length > 0) {
            this.addResource(data.id, this.files);
          } else {
            this.notificationService.successDialog('Publicación actualizada correctamente', 'Aviso');
            this.onNoClick();
          }
        } else {
          this.notificationService.warningMessage('Ocurrió un error al actualizar la publicación', 'Aviso');
        }
       },
      error => {
        this.saveBtn.active = false;
        this.notificationService.warningMessage(error, 'Aviso');
    });
  }

  getPost(id: number): Promise<Post> {
    return this.postsService.show(id)
    .toPromise()
    .then((res) => {
      if (res.status) {
        return res.data;
      } else {
       return null;
      }
    })
    .catch((err) => {
      this.notificationService.warningMessage('Ocurrió un error al guardar las imágenes', 'Aviso');
      this.saveBtn.text = 'Reintentar';
      this.saveResourcesFailed = true;
      return null;
    });
  }

  addResource(postId: number, files: File[]): void {
    this.postsService.addResource(postId, files)
    .toPromise()
    .then(async (res) => {
      if (res.status) {
        this.notificationService.successDialog('Publicación guardada correctamente', 'Aviso');

        if (this.action === 'edit') {
          this.result.data = await this.getPost(postId);
        }

        this.onNoClick();
      } else {
        this.notificationService.warningMessage('Ocurrió un error al guardar las imágenes', 'Aviso');
        this.saveBtn.text = 'Reintentar';
        this.saveResourcesFailed = true;
      }
      this.saveBtn.active = false;
    })
    .catch((err) => {
      this.notificationService.warningMessage('Ocurrió un error al guardar las imágenes', 'Aviso');
      this.saveBtn.text = 'Reintentar';
      this.saveResourcesFailed = true;
      this.saveBtn.active = false;
    });
  }

  async delResource(resourceId: number): Promise<boolean> {
    const result = await this.postsService.deleteResource(resourceId)
    .toPromise()
    .then((res) => {
      this.result = res;
      return res.status;
    })
    .catch((err) => {
      return false;
    });

    return result;
  }
}
