import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ICharge, IPaymentData, IPaymentHistoryRes, IPaymentRes } from 'src/app/interfaces/payment';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  API_URL = environment.apiAuthURL;
  method = 'payments/';

  constructor(private http: HttpClient) {}

  generatePayment(paymentData: IPaymentData): Observable<ICharge> {
    return this.http.post<ICharge>(`${this.API_URL}/${this.method}charge`, {
      ...paymentData
    });
  }

  chargeStatus(chargeId: string): Observable<IPaymentRes> {
    return this.http.post<IPaymentRes>(`${this.API_URL}/${this.method}charge-status`, {
      charge_id: chargeId
    });
  }

  cancelSuscription(): Observable<IPaymentRes> {
    return this.http.post<IPaymentRes>(`${this.API_URL}/cancel-subscription`, {
    });
  }

  paymentHistory(): Observable<IPaymentHistoryRes> {
    return this.http.get<IPaymentHistoryRes>(`${this.API_URL}/payment-history`, {});
  }
}
