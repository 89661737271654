import { I } from '@angular/cdk/keycodes';
import { DatePipe, PlatformLocation } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { timer } from 'rxjs';
import { ICharge } from 'src/app/interfaces/payment';
import { ISupplierData } from 'src/app/interfaces/user-auth';
import { AlertService } from 'src/app/services/alert/alert.service';
import { DataService } from 'src/app/services/data/data.service';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { PaymentDetailComponent } from './dialogs/payment-detail/payment-detail.component';
import { PaymentFormComponent } from './dialogs/payment-form/payment-form.component';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.sass']
})
export class SubscriptionComponent implements OnInit {
  skeletonActive = true;
  showPayment = false;
  currentDate: Date =  new Date();

  // history = [
  //   {amount: 299.00 , description: 'Pago de suscripción', date: '01/03/2022'},
  //   {amount: 299.00 , description: 'Pago de suscripción', date: '01/02/2022'},
  //   {amount: 299.00 , description: 'Pago de suscripción', date: '01/01/2022'}
  // ];

  supplier: ISupplierData;

  // Payment status
  paymentMsgs = [];
  showPaymentMsg = false;
  chargeId: string;

  constructor(
    private location: PlatformLocation,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private dataService: DataService,
    private notificationService: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    private datepipe: DatePipe,
    private paymentService: PaymentService,
  ) {}

  ngOnInit(): void {
    this.refreshData();
    // this.chargeId = this.route.snapshot.queryParams.id;
    // if (this.route.snapshot.params.status && this.chargeId) {
    //   this.chargeStatus(this.chargeId, this.route.snapshot.params.activate ? true : false);
    //   this.router.navigate([this.location.pathname], { replaceUrl: true }); // Reset data on url
    // }
  }

  refreshData(): void {
    this.skeletonActive = true;
    setTimeout(() => {
      this.getPaymentHistory();
    }, 500);
  }

  openPaymentDetailDialog(): void {
    const dialogRef = this.dialog.open(PaymentDetailComponent, {
      data: this.supplier,
      width: '400px'
    });

    dialogRef.afterClosed().subscribe((result: any) => {
    });
  }

  cancelSuscription(): void {
    this.notificationService.confirmDialog(
      `La suscripción quedará activa hasta el ${this.datepipe.transform(this.supplier.expiry_date, 'yyyy-MM-dd')} y los cargos automáticos ya no se ralizarán.`,
      'Cancelar',
      'Si',
      '¿Estás seguro de cacelar la suscripción?'
    )
    .then(async (res) => {
      if (res.isConfirmed) {
        this.paymentService.cancelSuscription().subscribe(
          async (response) => {
            if (response.status) {
              this.notificationService.successDialog(String(response.data), 'Aviso');
              this.getPaymentHistory();
            } else {
              this.notificationService.warningMessage(String(response.data), 'Aviso');
            }
          },
          error => {
            this.notificationService.warningMessage(error, 'Aviso');
        });
      }
    });
  }

  openPaymentDialog(): void {
    const dialogRef = this.dialog.open(PaymentFormComponent, {
      data: {
      },
      width: '500px',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(async (result: ICharge) => {
      if (result && (result.status === 'completed' || result.status === 'active') ) {
        this.showPayment = false;
        if (!this.supplier.active) {
          this.paymentMsgs.push({severity: 'success', summary: 'AVISO', detail: 'Su pago se realizó con éxito, ¡Gracias!, se recargará la página en unos instantes'});

          timer(5000).subscribe(x => {
            this.spinner.show();
            timer(15000).subscribe(async x => {
              await this.dataService.getProfile();
              await this.dataService.setGlobalData();
              window.location.reload();
            });
          });
        } else {
          this.paymentMsgs.push({severity: 'success', summary: 'AVISO', detail: 'Su pago se realizó con éxito, ¡Gracias!'});
          this.refreshData();

          timer(8000).subscribe(x => {
            this.paymentMsgs = [];
          });
        }
      }
    });
  }

  getPaymentHistory(): void {
    this.paymentService.paymentHistory().subscribe(
      res => {
        if (res.status) {
          this.supplier = res.data;
          if (this.supplier.expiry_date) {
            this.showPayment = this.currentDate.getTime() >= new Date(this.supplier.expiry_date).getTime();
          } else {
            this.showPayment = true;
          }
        }
        this.skeletonActive = false;
      },
      error => {
        this.notificationService.warningMessage(error, 'Aviso');
        this.skeletonActive = false;
      }
    );
  }

  chargeStatus(chargeId: string, activate?: boolean): void {
    this.paymentService.chargeStatus(chargeId).subscribe(
      async res => {
        if (res.status) {
          if (activate) {
            this.paymentMsgs.push({severity: 'success', summary: 'AVISO', detail: 'Su pago se realizó con éxito, ¡Gracias!, se recargará la página en unos instantes'});
            await this.dataService.getProfile();
            await this.dataService.setGlobalData();

            timer(8000).subscribe(x => {
              window.location.reload();
            });
          } else {
            this.paymentMsgs.push({severity: 'success', summary: 'AVISO', detail: 'Su pago se realizó con éxito, ¡Gracias!'});
          }
        } else {
          this.paymentMsgs.push({severity: 'warn', summary: 'AVISO', detail: 'Su pago no se realizó correctamente, reintenta el pago'});
        }

        timer(8000).subscribe(x => {
          this.paymentMsgs = [];
        });
      },
      error => {
        this.notificationService.warningMessage(error, 'Aviso');
      }
    );
  }
}
