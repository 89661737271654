import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IProfileRes, IUpdatePassword, IUserData } from 'src/app/interfaces/user-auth';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  API_URL = environment.apiURL;
  route = 'profile';

  constructor(
    private http: HttpClient
  ) { }

  get(): Observable<IProfileRes> {
    return this.http.get<IProfileRes>(`${this.API_URL}${this.route}`, {});
  }

  update(data: IUserData): Observable<IProfileRes> {
    return this.http.put<IProfileRes>(`${this.API_URL}${this.route}/${data.id}`, data);
  }

  updatePassword(data: IUpdatePassword): Observable<IProfileRes> {
    return this.http.post<IProfileRes>(`${this.API_URL}auth/update-password`, data);
  }
}
