export const environment = {
    production: true,
    URL: 'https://qa-admin.ipartymexico.com',
    apiURL: 'https://qa-api.ipartymexico.com/',
    apiAuthURL: 'https://qa-api.ipartymexico.com/v1',
    resourceURL: 'https://qa-api.ipartymexico.com/image',
    firebaseConfig : {
      apiKey: "AIzaSyB4mslj7K947X0wCVqKUscb6iKUrJK3egc",
      authDomain: "iparty-mexico.firebaseapp.com",
      projectId: "iparty-mexico",
      storageBucket: "iparty-mexico.appspot.com",
      messagingSenderId: "370090962683",
      appId: "1:370090962683:web:4b551c4136eae765874a5e",
      measurementId: "G-BQJYBJTHNN"
    },
    oneSignal: {
      apiID: "MDVlMDdiMzYtMzljNy00YTk3LTlkODEtM2Y2NjEwZjc2YTRm",
      appID: "642b039f-b8a2-4cfa-9767-b00bcbf3b431",
      safariID: "web.onesignal.auto.4bf63793-66f2-4d83-98c9-d7c9e724d029"
    },
    googleMaps: {
      apiKey: "AIzaSyBI8WgovMDsevvQep9bVyAhd227N6Ia0Nc"
    },
    openpay: {
      openPayId: "ma4vuy9qt9oizwve17qq",
      openPayApiKey: "pk_e4f2f31cc6144605bae415bfd597eb9f"
    }
  };
  