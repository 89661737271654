import { Component, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Address } from 'src/app/core/models/address';
import { IAddressRes } from 'src/app/interfaces/address';
import { IMarker } from 'src/app/interfaces/maps';
import { AlertService } from 'src/app/services/alert/alert.service';
import { DataService } from 'src/app/services/data/data.service';
import { AddressesService } from 'src/app/services/supplier/addresses.service';
import { FormValidatorService } from 'src/app/services/validator/form-validator.service';

@Component({
  selector: 'app-form-dialog',
  templateUrl: './form-dialog.component.html',
  styleUrls: ['./form-dialog.component.sass']
})
export class FormDialogComponent implements OnDestroy {
  action: string;
  dialogTitle: string;
  form: FormGroup;
  dataSource: Address;
  result: IAddressRes;

  location: IMarker;
  locationSubscription: Subscription;

  marker: IMarker;

  validationMessages = {
    address: [{ type: 'required', message: 'Dirección requerido' }]
  };

  constructor(
    public dialogRef: MatDialogRef<FormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private formValidatorService: FormValidatorService,
    private notificationService: AlertService,
    private addressService: AddressesService,
    private dataService: DataService
  ) {
    // Set the defaults
    this.action = data.action;
    if (this.action === 'edit') {
      this.dialogTitle = 'Editar dirección';
      this.dataSource = {...data.data};

      this.marker = {
        lat: this.dataSource.latitude,
        lng: this.dataSource.longitude,
        label: 'A',
        draggable: true,
        address: this.dataSource.address
      };
    } else {
      this.dialogTitle = 'Nuevo registro';
      this.dataSource = new Address({});
    }

    this.form = this.createForm();

    // Listen to location changes
    this.locationSubscription = this.dataService.location.subscribe((value) => {
      if (value) {
        this.location = value;
        this.dataSource.address = this.location.address;
        this.dataSource.latitude = this.location.lat;
        this.dataSource.longitude = this.location.lng;

        this.form.controls.latitude.setValue(this.dataSource.latitude);
        this.form.controls.longitude.setValue(this.dataSource.longitude);
        this.form.controls.address.setValue(this.dataSource.address);
      }
    });
  }

  ngOnDestroy(): void {
    this.onNoClick();
  }

  createForm(): FormGroup {
    return this.fb.group({
      id: [this.dataSource.id],
      address: [{value: this.dataSource.address, disabled: true}, [Validators.required]],
      latitude: [{value: this.dataSource.latitude, disabled: false}, []],
      longitude: [{value: this.dataSource.longitude, disabled: false}, []],
      coverage: [this.dataSource.coverage, []]
    });
  }

  onNoClick(): void {
    this.dataService.setLocationValue(null);
    this.dialogRef.close(this.result);
    this.locationSubscription.unsubscribe();
  }

  public save(): void {
    if (this.form.valid) {
      this.dataSource = this.form.value;
      this.dataSource.address = this.form.controls.address.value;
      if (this.action === 'edit') {
        this.updateItem(this.dataSource);
      } else {
        this.addItem(this.dataSource);
      }
    } else {
      this.formValidatorService.allFields(this.form);
      this.notificationService.warningMessage('Completa la información', 'Aviso');
    }
  }

  addItem(item: Address): void {
    this.addressService.create(item).subscribe(
      res => {
        if (res.status) {
          this.result = res;
          this.notificationService.successDialog('Acción completa');
          this.dialogRef.close(this.result);
        } else {
          this.notificationService.warningMessage(res.msg);
        }
      },
      error => {
        this.notificationService.warningMessage(error, 'Aviso');
      }
    );
  }

  updateItem(item: Address): void {
    this.addressService.update(item).subscribe(
      res => {
        if (res.status) {
          this.result = res;
          this.notificationService.successDialog('Acción completa');
          this.dialogRef.close(this.result);
        } else {
          this.notificationService.warningMessage(res.msg);
        }
       },
       error => {
        this.notificationService.warningMessage(error, 'Aviso');
    });
  }
}
