<mat-card [ngClass]="'payment-content'">
    <div class="modalHeader">
        <div class="editRowModal">
            <div class="modalHeader clearfix">
            <div class="modal-about">
                <div class="font-weight-bold font-17">
                {{dialogTitle}}
                </div>
            </div>
            </div>
        </div>
        <button mat-icon-button (click)="dialogRef.close()" aria-label="Close dialog">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-card-content class="pb-2">
        <form class="payment-form row" [formGroup]="form">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                <mat-form-field class="default-full-width" appearance="outline">
                    <mat-label>Nombre</mat-label>
                    <input matInput formControlName="card_holder" [placeholder]="'Como aparece en tu tarjeta'">
                    <mat-icon matSuffix>person</mat-icon>
                    <mat-error *ngIf="form.get('card_holder').hasError('required')">
                        Ingresa el nombre como aparece en tu tarjeta
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-1">
                <mat-form-field class="default-full-width" appearance="outline">
                    <mat-label>Número de tarjeta</mat-label>
                    <input matInput formControlName="card_number" [mask]="cardMask" [placeholder]="cardMask" (input)="cardType($event)">
                    <mat-icon matSuffix>credit_card</mat-icon>
                    <mat-error *ngIf="form.get('card_number').hasError('required')">
                        Ingresa el número de tu tarjeta
                    </mat-error>
                </mat-form-field>
                <!-- <p-inputMask id="paymentNumber" formControlName="card_number" 
                [mask]="cardMask" slotChar=" "
                unmask="true"
                (input)="cardType($event)"> -->
                <!-- </p-inputMask> -->
            </div>
            <div class="col-2 mb-1 pr-0">
                <mat-form-field class="default-full-width" appearance="outline">
                    <mat-label>Mes</mat-label>
                    <input matInput formControlName="month" mask="99" [placeholder]="'MM'">
                    <mat-error *ngIf="form.get('month').hasError('required')">
                        Mes requerido
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-2 mb-1 pr-0">
                <mat-form-field class="default-full-width" appearance="outline">
                    <mat-label>Año</mat-label>
                    <input matInput formControlName="year" mask="99" [placeholder]="'YY'">
                    <mat-error *ngIf="form.get('year').hasError('required')">
                        Año requerido
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-2 mb-1 pr-0">
                <mat-form-field class="default-full-width" appearance="outline">
                    <mat-label>CVV</mat-label>
                    <input matInput formControlName="cvv" [mask]="cvvMask" [placeholder]="cvvMask" [hiddenInput]="true">
                    <mat-error *ngIf="form.get('cvv').hasError('required')">
                       Requerido
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col d-flex justify-content-end t-2 mb-2">
                <img class="" src="assets/images/openpay/credit_card.png" style="max-width: 120px; height: 30px;">
            </div>
            <div class="d-flex pt-2 pl-4 pr-4 pb-2" style="font-weight: 600;">
                <div class="d-flex flex-column align-items-start align-content-center pr-4">
                    <div class="d-flex align-items-center align-content-center custom-checkbox">
                        <mat-checkbox class="d-flex align-items-center align-content-center mr-1" formControlName="tc">Acepto </mat-checkbox>
                        <a class="col-blue link" style="height: 27.5px;" (click)="openDoc('assets/docs/t&c/iparty_TC.pdf')">términos y condiciones</a>
                    </div>
                    <mat-error class="link-error" *ngIf="!this.form.get('tc').pristine && form.get('tc').hasError('required')">
                      Debes aceptar los términos y condiciones
                    </mat-error>
                </div>
                <div class="d-flex flex-column align-items-start align-content-center custom-checkbox">
                    <div class="d-flex align-items-center align-content-center">
                        <mat-checkbox class="d-flex align-items-center align-content-center mr-1" formControlName="pp">Acepto </mat-checkbox>
                        <a class="col-blue link" style="height: 27.5px;" (click)="openDoc('assets/docs/t&c/iparty_AP.pdf')">aviso de privacidad</a>
                    </div>
                    <mat-error class="link-error" *ngIf="!this.form.get('pp').pristine && form.get('pp').hasError('required')">
                        Debes aceptar el aviso de privacidad
                    </mat-error>
                </div>
            </div>
            <div class="d-flex pt-2 pl-4 pr-4 pb-2" style="font-weight: 500;">
                <div class="d-flex flex-column align-items-start align-content-center">
                    <div class="d-flex align-items-center align-content-center">
                        <mat-checkbox class="d-flex align-items-center align-content-center mr-1" formControlName="subscription">                         
                            <a class="" style="height: 27.5px; color: black;"
                            #tooltip="matTooltip"
                            matTooltip="Se realizará el cobro automáticamente cada mes"
                            matTooltipPosition="right"
                            aria-label="suscription">
                                Generar cargo recurrente
                            </a>
                        </mat-checkbox>
                    </div>
                </div>
            </div>
        </form>
        <p-messages [(value)]="paymentFormMsgs" [enableService]="false"></p-messages>
        <div class="openpay-container d-flex justify-content-between pt-5">
            <div class="d-flex align-items-center align-content-center">
                <img class="mr-2" width="30" src="assets/images/openpay/openpay_safe.png">
                <p class="col-green m-0">Tus pagos se realizan de forma segura con encriptación de 256 bits</p>
            </div>
            <img class="ml-4" width="150" src="assets/images/openpay/openpay_color.jpg">
        </div>
    </mat-card-content>
    <mat-card-actions ngClass="d-flex justify-content-end p-1">
        <button type="button" mat-button (click)="onClose()">Cancelar</button>
        <mat-spinner-button
            type="button"
            ngClass="spinner-btn mr-3"
            [options]="paymentBtn" 
            (click)="makePayment()">
        </mat-spinner-button>
    </mat-card-actions>
</mat-card>

  