import { Injectable } from '@angular/core';
import { IPaymentCode, IUserTokenData, IUserPayment } from 'src/app/interfaces/payment';
import { environment } from 'src/environments/environment';

declare const OpenPay: any;

@Injectable({
  providedIn: 'root'
})
export class OpenpayService {
  private paymentErrorCodes: IPaymentCode[] = [
    {
      code: 3001,
      desc: 'La tarjeta fue declinada por el banco'
    },
    // {
    //   code: 3002,
    //   desc: 'La tarjeta ha expirado'
    // },
    // {
    //   code: 3003,
    //   desc: 'La tarjeta no tiene fondos suficientes'
    // },
    // {
    //   code: 3004,
    //   desc: 'La tarjeta ha sido identificada como una tarjeta robada'
    // },
    // {
    //   code: 3005,
    //   desc: 'La tarjeta ha sido rechazada por el sistema antifraudes'
    // },
    // {
    //   code: 3006,
    //   desc:
    //     'La operación no esta permitida para este cliente o esta transacción'
    // },
    {
      code: 3007,
      desc: 'Deprecado. La tarjeta fue declinada'
    },
    {
      code: 3008,
      desc: 'La tarjeta no es soportada en transacciones en línea'
    },
    // {
    //   code: 3009,
    //   desc: 'La tarjeta fue reportada como perdida'
    // },
    // {
    //   code: 3010,
    //   desc: '	El banco ha restringido la tarjeta'
    // },
    // {
    //   code: 3011,
    //   desc:
    //     'El banco ha solicitado que la tarjeta sea retenida. Contacte al banco'
    // },
    {
      code: 3012,
      desc:
        'Se requiere solicitar al banco autorización para realizar este pago'
    }
  ];
  private userTokenData: IUserTokenData;

  constructor() { }

  private setCredentials(): void {
    OpenPay.setId(environment.openpay.openPayId);
    OpenPay.setApiKey(environment.openpay.openPayApiKey);
  }

  private setSandboxMode(): void {
    OpenPay.setSandboxMode(true);
  }

  getSanboxMode(): boolean {
    return OpenPay.getSandboxMode();
  }

  getDeviceSessionId(): string {
    return OpenPay.deviceData.setup();
  }

  setInitialConfig(): void {
    this.setCredentials();
    this.setSandboxMode(); // uncommented line for sandbox mode
  }

  validateUserCard(): any {
     // Validate Card Number
     if (!OpenPay.card.validateCardNumber(this.userTokenData.card_number)) {
      return  { severity: 'warn', summary: 'Aviso', detail: 'Tipo de tarjeta inválida'};
    }

    // Validate Card Type
    // if (!OpenPay.card.cardType(this.userTokenData.card_number)) {
    //   return  { severity:'warn', summary: 'Aviso', detail: 'Tipo de tarjeta inválida'};
    // }

    // Validate Card CVC
     if (!OpenPay.card.validateCVC(this.userTokenData.cvv2, this.userTokenData.card_number)) {
      return { severity: 'warn', summary: 'Aviso', detail: 'Código de seguridad inválido'};
    }

    // Validate Card Expiration
     if (!OpenPay.card.validateExpiry(this.userTokenData.expiration_month,
        this.userTokenData.expiration_year)) {
        return { severity: 'warn', summary: 'Aviso', detail: 'Fecha de expiración inválida'};
    }
     return null;
  }

  setTokenData(paymentData: IUserPayment, addressData?: any): any {
    this.userTokenData = {
      card_number: paymentData.card_number,
      holder_name: paymentData.card_holder,
      expiration_year: paymentData.year,
      expiration_month: paymentData.month,
      cvv2: paymentData.cvv
    };
  }

  createCardToken(): Promise<any> {
    return new Promise((resolve, reject) => {
      OpenPay.token.create(
        this.userTokenData,
        (success) => {
          resolve(success);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getCardErrorMsg(errorCode: any): any {
    const error = this.paymentErrorCodes.find(
      (element) => element.code === errorCode
    );
    return error ? error.desc : 'Tarjeta declinada, contacte al banco';
  }
}
