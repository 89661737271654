import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { DataService } from '../../data/data.service';

@Injectable({
  providedIn: 'root'
})
export class SupplierInactiveGuard implements CanActivate {

  constructor(
    private dataService: DataService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = this.dataService.profileValue;
    if (user.role_id === 3 && user.supplier && !user.supplier.active) {
      return true;
    }
    this.router.navigate(['/dashboard']);
    return false;
  }

}
