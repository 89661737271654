import { IResource } from 'src/app/interfaces/resource';
import { Address } from './address';
import { Categories } from './categories';
import { Question } from './questions';
import { SubCategories } from './sub-categories';
import { ISupplierData } from 'src/app/interfaces/user-auth';

export class Post {
    id: number;
    name: string;
    description: string;
    price: number;
    discount: number;
    'discount_days': string;
    'expiry_date': string;
    visible: boolean;
    approved: number;
    'required_limit': boolean;
    stock: number;
    'address_id': number;
    address: Address;
    'supplier_id': number;
    'category_id'?: number;
    category?: Categories;
    'subcategory_id'?: number;
    subcategory?: SubCategories;
    'created_at': string;
    'updated_at': string;
    questions: Question[];
    '__meta__': {
      eventos: number;
      "sin_respuesta": number;
    };
    pivot: {
      'post_id': any,
      'event_id': any,
      rating: number
    };
    'total_events'?: number;
    'total_rating'?: number;
    rating?: number;
    resources: IResource[];
    isSelected: boolean;
    supplier?:  ISupplierData;

    constructor(post) {
      {
        this.id  = post.id || '';
        this.name  = post.name || '';
        this.description  = post.description || '';
        this.price  = post.price || 1;
        this.discount  = post.discount || 0;
        this.discount_days  = post.discount_days || '';
        this.expiry_date  = post.expiry_date || new Date();
        this.visible  = post.visible || true;
        this.approved  = post.approved || 1;
        this.required_limit  = post.required_limit || '';
        this.stock  = post.stock || 1;
        this.address_id  = post.address_id || '';
        this.address = post.address || '';
        this.supplier_id  = post.supplier_id || '';
        this.category_id  = post.category_id || '';
        this.category = post.category || '';
        this.subcategory_id  = post.subcategory_id || '';
        this.subcategory = post.subcategory || '';
        this.created_at  = post.created_at || '';
        this.total_events  = post.total_events || '';
        this.total_rating  = post.total_rating || '';
        this.rating  = post.rating || 0;
        this.__meta__ = post.__meta__ || { eventos : 0, sin_respuesta : 0};
        this.resources = post.resources || [];
        this.isSelected = false;
        this.pivot = post.pivot || { event_id: 0, post_id: 0, rating: 0 };
      }
    }
}