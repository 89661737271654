import { Router, NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  HostListener,
  OnDestroy
} from '@angular/core';
import { ADMIN_ROUTES, SUPPLIER_ROUTES, SUPPLIER_PAYMENT_ROUTES, MODERATOR_ROUTES } from './sidebar-items';
import { DataService } from 'src/app/services/data/data.service';
import { Subscription } from 'rxjs';
import { IUserData } from 'src/app/interfaces/user-auth';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.sass']
})
export class SidebarComponent implements OnInit, OnDestroy {
  public sidebarItems: any[];
  level1Menu = '';
  level2Menu = '';
  level3Menu = '';
  public innerHeight: any;
  public bodyTag: any;
  listMaxHeight: string;
  listMaxWidth: string;
  headerHeight = 60;
  routerObj = null;
  userSubscription: Subscription;
  user: IUserData;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private dataService: DataService,
    private router: Router
  ) {
    this.routerObj = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // logic for select active menu in dropdown
        const currenturl = event.url.split('?')[0];
        this.level1Menu = currenturl.split('/')[1];
        this.level2Menu = currenturl.split('/')[2];

        // close sidebar on mobile screen after menu select
        this.renderer.removeClass(this.document.body, 'overlay-open');
      }
    });

    this.userSubscription = this.dataService.profile.subscribe((data) => {
      this.user = data;
    });
  }

  @HostListener('window:resize', ['$event'])
  windowResizecall(event) {
    this.setMenuHeight();
    this.checkStatuForResize(false);
  }

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.renderer.removeClass(this.document.body, 'overlay-open');
    }
  }

  callLevel1Toggle(event: any, element: any): void {
    if (element === this.level1Menu) {
      this.level1Menu = '0';
    } else {
      this.level1Menu = element;
    }
    const hasClass = event.target.classList.contains('toggled');
    if (hasClass) {
      this.renderer.removeClass(event.target, 'toggled');
    } else {
      this.renderer.addClass(event.target, 'toggled');
    }
  }

  callLevel2Toggle(event: any, element: any): void {
    if (element === this.level2Menu) {
      this.level2Menu = '0';
    } else {
      this.level2Menu = element;
    }
  }

  callLevel3Toggle(event: any, element: any): void {
    if (element === this.level3Menu) {
      this.level3Menu = '0';
    } else {
      this.level3Menu = element;
    }
  }

  ngOnInit(): void {
    if (this.user) {
      switch (this.user.role_id) {
        case 3: // Proveedor
          if (this.user.supplier && !this.user.supplier.active) {
            this.sidebarItems = SUPPLIER_PAYMENT_ROUTES.filter((sidebarItem) => sidebarItem);
          } else {
            this.sidebarItems = SUPPLIER_ROUTES.filter((sidebarItem) => sidebarItem);
          }
          break;
        case 4: // Moderator
          this.sidebarItems = MODERATOR_ROUTES.filter((sidebarItem) => sidebarItem);
          break;
        case 5: // Admin
          this.sidebarItems = ADMIN_ROUTES.filter((sidebarItem) => sidebarItem);
          break;
      }
    }

    this.initLeftSidebar();
    this.bodyTag = this.document.body;
  }

  ngOnDestroy(): void {
    this.routerObj.unsubscribe();
  }

  initLeftSidebar(): void {
    const _this = this;
    // Set menu height
    _this.setMenuHeight();
    _this.checkStatuForResize(true);
  }

  setMenuHeight(): void {
    this.innerHeight = window.innerHeight;
    const height = this.innerHeight - this.headerHeight;
    this.listMaxHeight = height + '';
    this.listMaxWidth = '500px';
  }

  isOpen(): void {
    return this.bodyTag.classList.contains('overlay-open');
  }

  checkStatuForResize(firstTime): void {
    if (window.innerWidth < 1170) {
      this.renderer.addClass(this.document.body, 'ls-closed');
    } else {
      this.renderer.removeClass(this.document.body, 'ls-closed');
    }
  }

  mouseHover(e): void {
    const body = this.elementRef.nativeElement.closest('body');

    if (body.classList.contains('submenu-closed')) {
      this.renderer.addClass(this.document.body, 'side-closed-hover');
      this.renderer.removeClass(this.document.body, 'submenu-closed');
    }
  }

  mouseOut(e): void {
    const body = this.elementRef.nativeElement.closest('body');
    if (body.classList.contains('side-closed-hover')) {
      this.renderer.removeClass(this.document.body, 'side-closed-hover');
      this.renderer.addClass(this.document.body, 'submenu-closed');
    }
  }

  onErrorPicture(event: any): void {
    event.target.src = "assets/images/iparty/ipa_user.png";
    event.onerror = null;
  }
}
