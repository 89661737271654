import { RouteInfo } from './sidebar.metadata';

export const ADMIN_ROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'General',
    moduleName: '',
    icon: '',
    class: '',
    groupTitle: true,
    submenu: []
  },
  {
    path: 'dashboard/main',
    title: 'Estadísticas',
    moduleName: 'dashboard',
    icon: 'fas fa-tachometer-alt',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: '/sections/user',
    title: 'Usuarios',
    moduleName: 'sections',
    icon: 'fas fa-user-alt',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: '/sections/admin/posts/check',
    title: 'Publicaciones listas',
    moduleName: 'sections',
    icon: 'fas fa-list-alt',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: '',
    title: 'Catálogos',
    moduleName: 'sections',
    icon: 'far fa-file-alt',
    class: '',
    groupTitle: true,
    submenu: []
  },
  {
    path: '/sections/categories',
    title: 'Categorías',
    moduleName: 'sections',
    icon: 'fas fa-book-open',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: '/sections/notifications',
    title: 'Notificaciones',
    moduleName: 'sections',
    icon: 'fas fa-bell',
    class: '',
    groupTitle: false,
    submenu: []
  }
];

export const SUPPLIER_ROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'General',
    moduleName: '',
    icon: '',
    class: '',
    groupTitle: true,
    submenu: []
  },
  {
    path: 'dashboard/main',
    title: 'Estadísticas',
    moduleName: 'dashboard',
    icon: 'fas fa-tachometer-alt',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: 'sections/addresses',
    title: 'Direcciones',
    moduleName: 'sections',
    icon: 'fas fa-address-book',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: '',
    title: 'Publicaciones',
    moduleName: '',
    icon: '',
    class: '',
    groupTitle: true,
    submenu: []
  },
  {
    path: 'sections/posts',
    title: 'Mis publicaciones',
    moduleName: 'sections',
    icon: 'fas fa-tags',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: 'sections/posts-questions',
    title: 'Preguntas',
    moduleName: 'sections',
    icon: 'fas fa-question-circle',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: 'sections/calendar',
    title: 'Agenda',
    moduleName: 'sections',
    icon: 'fas fa-calendar',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: 'sections/notify',
    title: 'Notificar',
    moduleName: 'sections',
    icon: 'fas fa-bell',
    class: '',
    groupTitle: false,
    submenu: [],
    disabled: true
  },
  {
    path: '',
    title: 'Eventos',
    moduleName: '',
    icon: '',
    class: '',
    groupTitle: true,
    submenu: []
  },
  {
    path: 'sections/events',
    title: 'Mis eventos',
    moduleName: 'sections',
    icon: 'fa fa-birthday-cake',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: 'sections/questions',
    title: 'Preguntas',
    moduleName: 'sections',
    icon: 'fas fa-question-circle',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: 'sections/invite',
    title: 'Invitar',
    moduleName: 'sections',
    icon: 'fa fa-paper-plane',
    class: '',
    groupTitle: false,
    submenu: []
  },
];

export const SUPPLIER_PAYMENT_ROUTES: RouteInfo[] = [
  {
    path: 'subscription',
    title: 'Mi suscripción',
    moduleName: 'sections',
    icon: 'fa fa-credit-card',
    class: '',
    groupTitle: false,
    submenu: []
  },
];

export const MODERATOR_ROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'General',
    moduleName: 'general',
    icon: 'far fa-file-alt',
    class: '',
    groupTitle: true,
    submenu: []
  },
  {
    path: '/sections/posts/check',
    title: 'Publicaciones listas',
    moduleName: 'general',
    icon: 'fas fa-list-alt',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: '/sections/all-posts/review',
    title: 'Publicaciones',
    moduleName: 'general',
    icon: 'fas fa-list-alt',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: '',
    title: 'Revisión',
    moduleName: 'sections',
    icon: 'far fa-file-alt',
    class: '',
    groupTitle: true,
    submenu: []
  },
  {
    path: '/sections/posts/review',
    title: 'Publicaciones',
    moduleName: 'sections',
    icon: 'fas fa-check-square',
    class: '',
    groupTitle: false,
    submenu: []
  },
  {
    path: '/sections/notification/review',
    title: 'Notificaciones',
    moduleName: 'sections',
    icon: 'fas fa-bell',
    class: '',
    groupTitle: false,
    submenu: []
  }
];
