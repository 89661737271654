<div class="content">
    <div class="d-flex col-12 justify-content-center align-items-center search">
        <div class="d-flex" style="width: 60%;">
            <mat-form-field class="default-form-field search mr-1" appearance="outline">
              <mat-icon matPrefix>search</mat-icon>
                <input matInput type="text" placeholder="Buscar dirección..." #filter autocomplete="off">
                <button *ngIf="filter.value" matSuffix mat-icon-button aria-label="Clear" (click)="filter.value = ''">
                  <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>        
        </div>
    </div>
    <div class="row p-0 m-0 justify-content-center">
        <agm-map 
            [fullscreenControl]="true" 
            [scrollwheel]="true" 
            [zoomControl]="true"
            [disableDefaultUI]="false" 
            [usePanning]="true" 
            [latitude]="lat" 
            [longitude]="lng" 
            [zoom]="zoom"
            [streetViewControl]="false" 
            (mapClick)="mapClicked($event)" 
            ngClass="maps"
            #agmMap>
            <agm-marker *ngIf="marker" [latitude]="marker.lat" [longitude]="marker.lng"
                [markerDraggable]="marker.draggable" (dragEnd)="markerDragEnd(marker, $event)">
                <agm-info-window>
                    <strong>{{ marker.address }}</strong>
                </agm-info-window>
            </agm-marker>
        </agm-map>
    </div>
</div>
