import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-list',
  templateUrl: './skeleton-list.component.html',
  styleUrls: ['./skeleton-list.component.sass']
})
export class SkeletonListComponent implements OnInit {

  items: number[] = [
    1, 2, 3, 4, 5, 6
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
