import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Page404Component } from './authentication/page404/page404.component';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';
import { SubscriptionComponent } from './sections/general/subscription/subscription.component';
import { AuthGuard } from './services/auth/guard/auth.guard';
import { SupplierInactiveGuard } from './services/auth/guard/supplier.inactive.guard';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/authentication/signin', pathMatch: 'full' },
      {
        path: 'dashboard',
        canActivate: [],
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule)
      },
      {
        path: 'configuration',
        loadChildren: () =>
          import('./sections/general/general.module').then(
            (m) => m.GeneralModule
          )
      },
      {
        path: 'subscription',
        canActivate: [SupplierInactiveGuard],
        component: SubscriptionComponent
      },
      {
        path: 'subscription/:status/:activate',
        canActivate: [],
        component: SubscriptionComponent
      },
      {
        path: 'sections',
        loadChildren: () =>
          import('./sections/sections.module').then(
            (m) => m.SectionsModule
          )
      }
    ]
  },
  {
    path: 'authentication',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      )
  },
  { path: '**', component: Page404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
