<mat-card [ngClass]="'payment-detail-content'">
    <div class="modalHeader">
        <div class="editRowModal">
            <div class="modalHeader clearfix">
            <div class="modal-about">
                <div class="font-weight-bold font-17">
                {{dialogTitle}}
                </div>
            </div>
            </div>
        </div>
        <button mat-icon-button (click)="dialogRef.close()" aria-label="Cerrar">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <mat-card-content class="pb-5">
        <p class="pb-3">Con tu suscripción puedes publicar, contestar dudas a tus clientes y que ellos puedan agendar tus servicos a sus eventos, además de enviar notificaciones a los usuarios registrados.</p>
        <p *ngIf="supplier.expiry_date else noExpiryDate">Suscripción activa hasta el <strong>{{supplier.expiry_date | date}}</strong></p>
        <ng-template #noExpiryDate>
            <div class="d-flex">
                <p class="mr-2">Fecha de expiración</p>
                <span class="label label-default" style="height: 20px; padding: 0px 10px;">No disponible</span>
            </div>
        </ng-template>

        <!-- <div class="d-flex"> -->
            <!-- <p class="mr-2">Renovación automática</p> -->
            <!-- <span class="label label-primary" style="height: 20px; padding: 0px 10px;">Activa</span> -->
            <!-- <span class="label label-default" style="height: 20px; padding: 0px 10px;">Inactivo</span> -->
        <!-- </div> -->
        <!-- <div class="d-flex">
            <p class="mr-2">Próximo pago</p>
            <span class="label label-default" style="height: 20px; padding: 0px 10px;">{{supplier.payment_day ? (supplier.payment_day | date) : 'No disponible' }}</span>
        </div> -->
    </mat-card-content>
    <mat-card-actions ngClass="d-flex justify-content-end p-1">
        <button type="button" mat-button (click)="onClose()">Cerrar</button>
    </mat-card-actions>
</mat-card>

  