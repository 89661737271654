
<div class="addContainer address-modal p-3 pb-2">
    <div class="modalHeader">
        <div class="editRowModal">
            <div class="modalHeader clearfix">
                <div class="modal-about">
                    <div class="font-weight-bold p-t-5 font-17">
                        Seleccionar dirección
                    </div>
                </div>
            </div>
        </div>
        <button mat-icon-button (click)="onNoClick()" aria-label="Cerrar">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div mat-dialog-content>
        <app-addresses-list [selectAddress]="selectAddress" (selectedAddressChange)="selectedAddress($event)">
        </app-addresses-list>
    </div>
</div>
  