<!-- Right Sidebar -->
<aside id="rightsidebar" class="right-sidebar" [ngClass]="isOpenSidebar ? 'open' : 'close'"
  (clickOutside)="onClickedOutside($event)">
  <div class="rightSidebarClose" (click)="toggleRightSidebar()"><i class="far fa-times-circle"></i></div>
  <div class="default-skin"
    [ngStyle]="{'position' : 'relative', 'max-height' : maxHeight+'px', 'max-width' : maxWidth+'px'}"
    [perfectScrollbar]>
    <div class="rightSetting">
      <p>ASPECTO GENERAL</p>
      <mat-button-toggle-group class="mt-2" [value]="isDarTheme ? 'dark' : 'light'">
        <mat-button-toggle (click)="lightThemeBtnClick()" value="light">Claro</mat-button-toggle>
        <mat-button-toggle (click)="darkThemeBtnClick()" value="dark">Oscuro</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div *ngIf="!isDarkSidebar" class="rightSetting">
      <p>ASPECTO SECUNDARIO</p>
      <ul class="default-choose-skin choose-theme list-unstyled">
        <li data-theme="white" [ngClass]="{'actived': selectedBgColor === 'white'}" (click)="selectTheme('white')">
          <div class="white-theme white-theme-border"></div>
        </li>
        <li data-theme="purple" [ngClass]="{'actived': selectedBgColor === 'purple'}" (click)="selectTheme('purple')">
          <div class="purple-theme"></div>
        </li>
      </ul>
    </div>
  </div>
</aside>
<!-- #END# Right Sidebar -->
