import { Injectable } from '@angular/core';
import Swal, { SweetAlertCustomClass } from 'sweetalert2'

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  public customStyle: SweetAlertCustomClass = {
    container: localStorage.getItem('menuOption') === 'menu_dark' ? 'dark-primary' : 'alert-primary',
    title: 'alert-title',
    icon: 'alert-icon',
    confirmButton: 'alert-confirm-btn',
    cancelButton: 'alert-cancel-btn',
    input: 'alert-input',
  };

  constructor() { }

  successDialog(text: string, title?: string) {
    return Swal.fire({
        position: 'center',
        icon: 'success',
        text,
        title,
        showConfirmButton: false,
        timer: 1500
    });
  }

  warningMessage(text: string, title?: string) {
    return Swal.fire({
        position: 'center',
        icon: 'warning',
        text,
        title,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#2AAA0B',
        showCloseButton: true
    });
  }

  errorMessage(text: string, title?: string) {
    return Swal.fire({
        position: 'center',
        icon: 'error',
        text,
        title,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#2AAA0B',
        showCloseButton: true
    });
  }

  confirmDialog(text: string, cancel: string, confirm: string, title?: string,
                closeBtn: boolean = false, allowOutsideClick: boolean = true) {
    return Swal.fire({
        position: 'center',
        icon: 'warning',
        title,
        text,
        showCancelButton: true,
        cancelButtonText: cancel,
        showConfirmButton: true,
        confirmButtonText: confirm,
        showCloseButton: closeBtn,
        allowOutsideClick,
        allowEscapeKey: false,
        customClass: this.customStyle
    });
  }

  okDialog(text: string, confirm: string) {
    return Swal.fire({
        position: 'center',
        icon: 'success',
        text,
        showConfirmButton: true,
        confirmButtonText: confirm,
        confirmButtonColor: '#2AAA0B',
        showCloseButton: true
    });
  }

  infoDialog(text: string, confirm?: string) {
    return Swal.fire({
        position: 'center',
        icon: 'info',
        text,
        showConfirmButton: true,
        confirmButtonText: confirm ? confirm : 'Cerrar',
        confirmButtonColor: '#2AAA0B',
        showCloseButton: true,
    });
  }

  timeMessage(text: string, time) {
    let timerInterval;
    return Swal.fire({
      html: text,
      timer: time,
      onBeforeOpen: () => {
          Swal.showLoading();
          timerInterval = setInterval(() => { }, 100);
      },
      onClose: () => {
          clearInterval(timerInterval);
      }
    });
  }
}
