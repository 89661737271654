import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Dimensions, ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'image-cropper-dialog',
  templateUrl: './image-cropper-dialog.component.html',
  styleUrls: ['./image-cropper-dialog.component.sass']
})
export class ImageCropperDialogComponent implements OnInit, OnChanges {
  @Input() imageChangedEvent: any = '';
  @Input() addFile = false;
  @Input() indexFile = 0;
  @Output() saveResource: EventEmitter<any> = new EventEmitter();

  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  aspectRatio = 1 / 1;
  showCropper = true;
  containWithinAspectRatio = true;
  imageURL: string;
  loading = false;

  cropperMinWidth = 600;
  cropperMinHeight = 600;

  constructor() { }

  ngOnInit(): void {
    this.loading = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.addFile && changes.addFile.currentValue) this.save();
  }

  save(): void {
    if (this.croppedImage) {
      fetch(this.croppedImage)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], 'File name.png', { type: 'image/png' });
          if (file) this.saveResource.emit({ file, index: this.indexFile, uri: this.croppedImage });
      });
    }
  }

  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.base64;
  }

  imageLoaded(): void {
    this.showCropper = true;
    this.loading = false;
  }

  cropperReady(sourceImageDimensions: Dimensions): void {
  }

  loadImageFailed(): void {
    console.error('Load image failed');
  }

}
