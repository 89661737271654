import { Injectable } from '@angular/core';
import { INotification, IReceivedNotification } from 'src/app/interfaces/notification';
import { IUserData } from 'src/app/interfaces/user-auth';
import { environment } from 'src/environments/environment';
import { DataService } from '../data/data.service';
import { NotificationsService } from '../supplier/notifications.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  onesignalURL = `/assets/js/onesignal/`;
  indexedDB = "ONE_SIGNAL_SDK_DB";
  user: IUserData;

  constructor(
    private dataService: DataService,
    private notificationDbService: NotificationsService
  ) { 
    this.user = this.dataService.profileValue;
  }

  async onInit(): Promise<void> {
    this.onLoad().then(async (OneSignal) => {
      OneSignal.SERVICE_WORKER_PARAM = { scope: this.onesignalURL };
      OneSignal.SERVICE_WORKER_PATH = `${this.onesignalURL}OneSignalSDKWorker.js`;
      OneSignal.SERVICE_WORKER_UPDATER_PATH = `${this.onesignalURL}OneSignalSDKUpdaterWorker.js`;

      await OneSignal.init({
        allowLocalhostAsSecureOrigin: true,
        appId: environment.oneSignal.appID,
        safari_web_id: environment.oneSignal.safariID,
        // notifyButton: {
        //   enable: true,
        // },
      });
      this.checkPermissions();
      OneSignal.on('notificationDisplay', (event: IReceivedNotification) => {
        // Validate if is not admin
        if (this.user.role_id !== 5) {
          // Get notifications - last 3 days history
          this.notificationDbService.received(this.user)
          .subscribe(response => {
            if (response.status) {
              this.dataService.setNotificationValue(response.data);
            }
          });
        }
      });
    });
  }

  async onLoad(): Promise<any> {
    window.OneSignal = window.OneSignal || [];
    return new Promise((resolve) => {
      window.OneSignal.push(() => {
        resolve(window.OneSignal);
      });
    });
  }

  async checkPermissions(): Promise<void> {
    const notiPermission = await window.OneSignal.getNotificationPermission();
    const pushPermission = await window.OneSignal.isPushNotificationsEnabled();

    if (notiPermission !== 'granted' || !pushPermission) {
      window.OneSignal.showSlidedownPrompt({force: true});
      // await this.updateSubscription(true);
      await window.OneSignal.registerForPushNotifications();
    }
  }

  async setExternalID(id: number): Promise<void> {
    if (window.OneSignal.config) {
      await window.OneSignal.setExternalUserId(String(id));
    }
  }

  async deleteExternalID(): Promise<void> {
    return await window.OneSignal.removeExternalUserId();
  }

  async getUserID(): Promise<string> {
    const result = await window.OneSignal.getUserId();
    return result;
  }

  async updateSubscription(value: boolean): Promise<void> {
    const result = await window.OneSignal.setSubscription(value);
    return result;
  }

  async unregister(): Promise<void> {
    const serviceWorker = await navigator.serviceWorker.getRegistration(
      environment.URL + this.onesignalURL);
    serviceWorker.unregister();
  }

  deleteIndexedDB(): void {
    const DBDeleteRequest = indexedDB.deleteDatabase(this.indexedDB);

    DBDeleteRequest.onblocked = (event) => {
      console.log('blocked', event);
    };

    DBDeleteRequest.onerror = (event) => {
      console.log('error', event);
    };

    DBDeleteRequest.onsuccess = (event) => {
      console.log('success', event);
    };
  }
}
