<div class="card address-card">
    <div class="header filters d-flex pt-2">
      <div class="d-flex" style="width: 50%;">
        <mat-form-field class="default-form-field mr-1" appearance="outline">
          <!-- <span matPrefix>+1 &nbsp;</span> -->
          <mat-icon matPrefix>search</mat-icon>
            <mat-label>Buscar...</mat-label>
            <input matInput type="text" [formControl]="searchControl" #filter>
            <button *ngIf="searchControl.value" matSuffix mat-icon-button aria-label="Clear" (click)="clear()">
              <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>        
      </div>
      <div class="d-flex justify-content-end" style="width: 50%;">
        <div class="d-flex justify-content-end">
          <button mat-mini-fab color="success" class="mr-2" (click)="openFormDialog()">
            <mat-icon class="col-white">add</mat-icon>
          </button>
          <button mat-mini-fab color="primary" class="mr-1" (click)="refreshData()">
            <mat-icon class="col-white">refresh</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="card-body p-0">
      <app-skeleton-list *ngIf="skeletonActive; else loaded"></app-skeleton-list>
      <ng-template #loaded>
        <ng-container *ngIf="dataSource.filteredData.length > 0 else noContent">
          <ul class="list-unstyled user-progress list-unstyled-border list-unstyled-noborder" [perfectScrollbar]>
            <li *ngFor="let address of dataSource.filteredData" class="lecture-list address-list" 
            [ngClass]="disableAddressTooltip? 'background-white' : 'background-gray'"
            matTooltip="Seleccionar dirección"
            matTooltipClass="default-tooltip-red"
            [matTooltipDisabled]="disableAddressTooltip"
            aria-label="Seleccionar dirección"
            (click)="selectAddress ? selectAddressAction(address) : ''">
                <div class="mr-4">
                  <div class="media-title font-20">
                    <mat-icon aria-label="" class="col-gray mr-1">contact_mail</mat-icon>
                  </div>
                </div>
                <div class="mr-4 address-txt d-flex">
                    <div class="d-flex media-title font-16 mr-3">{{address.address}}</div>
                    <div class="d-flex">
                      <h5 *ngIf="address.main_address" class="m-0">
                        <span class="label label-primary">Dirección principal</span>
                      </h5>
                    </div>
                </div>
                <div class="media-body d-flex justify-content-end address-actions">
                    <button mat-icon-button [matMenuTriggerFor]="menu"
                    #tooltip="matTooltip"
                    matTooltip="Opciones"
                    matTooltipPosition="right"
                    aria-label="Opciones"
                    (click)="$event.stopPropagation()">
                        <mat-icon>more_vert</mat-icon>
                        </button>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item (click)="openFormDialog(address)">
                          <mat-icon class="col-iparty-blue">create</mat-icon>
                          <span>Modificar</span>
                      </button>
                      <button mat-menu-item *ngIf="!address.main_address" (click)="confirmMainAddresUpdate(address)">
                          <mat-icon class="col-iparty-green">assignment_turned_in</mat-icon>
                          <span>Usar como dirección principal</span>
                      </button>
                      <button mat-menu-item (click)="confirmDelete(address)">
                          <mat-icon class="col-iparty-red">delete_forever</mat-icon>
                          <span>Eliminar</span>
                      </button>
                    </mat-menu>
                </div>
            </li>
          </ul>
        </ng-container>
        
        <ng-template #noContent>
          <app-no-content></app-no-content>
        </ng-template>
      </ng-template>
    </div>
</div>